@import "./slim/variables";

.slim-navbar {
    .active {
        border: 2px solid #1b84e7 !important;
        border-radius: 3px;
    }

    .interesting-exercises-span {
        width: min-content;
    }

    .square-8 {
        background-color: red !important;
    }
}

.dropdown-c {
    .dropdown-menu-right {
        display: block;

        &.notifications-cm {
            width: 380px;

            .notifications-list-ccmm {
                max-height: 500px;
                overflow-y: auto;
            }
        }
    }
}

.indicator.red {
    background-color: red !important;
    width: 8px !important;
    height: 8px !important;
    border-radius: 100%;
}

.messages-container {
    .before-chat-selection {
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    .messages-compose {
        color: rgb(13, 92, 240);

        &:hover {
            cursor: pointer;
        }
    }

    .chat-left {
        &:hover {
            cursor: pointer;
        }
    }

    .unread-message-in-chat {
        background-color: red !important;
        height: 12px !important;
        width: 12px !important;
    }

    .selected-chat {
        border: 1px solid blue !important;
    }

    .send-message-btn {
        &:hover {
            cursor: pointer;
        }
    }

    .media-bodyy {
        width: 100%;
        margin-left: 15px;
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        .unread-message-in-chat {
            background-color: red !important;
            height: 12px !important;
            width: 12px !important;
            display: flex;
            border-radius: 50%;
        }

        .div-with-red-point {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
        }
    }

    .eots-in-message-container {
        p {
            padding-top: 5px !important;
        }

        .eot-in-message-header {
            padding: 5px 12px 0px 12px;

            >span {
                padding: 3px;
                color: white;
                background: rgb(93, 93, 93);

                &:hover {
                    cursor: pointer;
                }
            }
        }

        padding: 5px;
        display: flex;
        flex-direction: column;
        background: #4662D4;
    }

    .eot-in-message {
        border: 1px solid rgb(217, 217, 217);
        margin-bottom: 5px;
        border-radius: 3px;
    }

    .reverse {
        .eots-in-message-container {
            background: #dee2e6;
        }

        .eot-in-message {
            border: 1px solid rgb(79, 79, 79);
        }
    }

}

.find-friend-message-modal {
    max-width: 480px;
    width: 100%;
}

.search-theory-keywords {
    border: 2px solid rgb(0, 195, 255);
}

.entity-in-list {
    border: 1px solid rgb(254, 178, 15);
    margin-top: 20px;
    color: black;
    padding: 20px 5px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 3px 4px 5px -1px rgba(212, 212, 212, 1);
    background: white;

    .choose-entity {
        width: 200px;
        margin: 20px auto 0px auto;
        border-radius: 5px;
    }

    .card-text {
        margin: 0px;
    }
}


.wrapper-2 {
    margin-top: 16px;
    padding: 10px 0;
    background: white;

    .search-by-isbns {
        padding: 20px 0;
    }

    .search-by-grades {
        padding: 20px 0;
    }

    .active {
        background: white;
        border-bottom: 1px solid white;
    }
}

.find-exercise-page {
    // .wrapper-2 {
    //     margin-top: 20px;
    //     padding: 10px;
    //     background: white;

    //     .search-by-isbns {
    //         padding: 20px 0;
    //     }

    //     .search-by-grades {
    //         padding: 20px 0;
    //     }

    //     .active {
    //         background: white;
    //         border-bottom: 1px solid white;
    //     }
    // }

    .saved-collections {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .mail-list {
        border: 0px;
    }
}

.tabb-x {
    &:hover {
        cursor: pointer;
    }
}

//.btn-outline-secondary:not(:disabled):not(.disabled):not(.active):focus,
.btn-outline-secondary,
.dropdown-toggle:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:active:focus,
.btn-outline-secondary:active {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: left;
    border: 1px solid #ced4da !important;
    border-radius: 3px;
    color: black !important;
    background-color: white !important;
    box-shadow: none;
}

button {
    &:disabled {
        background-color: #e9ecef !important;
        color: #495057 !important;
        opacity: 1 !important;
    }
}

.whole-bucket {
    background: white;
    margin-top: 20px;
    padding: 10px;
    position: relative;

    .collection-heading {
        display: flex;
        justify-content: space-between;

        .settings-send {
            height: 30px;

            .send-all-from-bucket,
            .visibility-setting {
                // position: absolute;
                // top: 10px;
                // right: 8px;
                border: 1px solid rgb(3, 174, 14);
                // color: rgb(79, 79, 79);
                padding: 4px;
                border-radius: 3px;
                margin-left: 8px;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

.slim-mainpanel {

    .dropdown-toggle:hover,
    .btn-outline-secondary:focus,
    .btn-outline-secondary:active:focus,
    .btn-outline-secondary:active,
    .btn-outline-secondary:not(:disabled):not(.disabled):not(.active):focus {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        text-align: left;
        border: 1px solid #ced4da !important;
        border-radius: 3px;
        color: black !important;
        background-color: white !important;
        box-shadow: none;
    }

    .dropdown-menu {
        width: 100%;
        text-align: left;
        transform: translate3d(0px, 39px, 0px) !important;
    }

    // .slim-pageheader {
    //     display: block;
    // }
    .slim-pagetitle {
        border: none;
        padding-left: 0;
    }

    .span-clickable {
        color: $slim-primary;
        cursor: pointer;
        font-weight: bold;
    }

    min-height: auto;
}

@media(min-width: 992px) {
    .slim-mainpanel {
        min-height: auto;
    }
}

.section-title-mg-t-0 {
    margin-top: 0px !important;
}

.saved-collections {
    .saved-eot-collection-preview {
        border: 1px solid rgb(180, 180, 180);
        padding: 5px 15px;
        max-width: 330px;
        flex-grow: 1;
        margin: 10px;

        &:hover {
            cursor: pointer;
        }
    }

    .selected-collection {
        border: 1px solid rgb(255, 102, 0);
    }
}

.exercise-page {
    min-width: fit-content;

    color: rgb(67, 67, 67);
    display: flex;
    flex-direction: column;

    .exercise-header {
        button {
            width: 300px;
            margin-bottom: 20px;
        }

        color: black;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
    }

    .after-text-separator {
        height: 1px;
        background-color: rgb(172, 172, 172);
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .exercise-video-container {
        border-top: 2px solid rgb(141, 235, 239);
        margin-top: 20px;
        padding-top: 20px;

        .exercise-video {
            color: black;
            background: rgb(255, 247, 247);
            margin: 0 auto;
            border: 1px solid rgb(210, 210, 210);
            width: 80%;
            height: 350px;
            box-shadow: 2px 0px 5px -1px rgba(102, 102, 102, 1);
        }
    }

    .exercise-text,
    .exercise-solution,
    .dynamic-values-container {
        color: black;
        border-top: none;
        border-left: none;
        border-right: none;
        max-width: 800px;
        width: 100%;
        margin: 0 auto;
    }

    .span-back {
        width: fit-content;
    }

    .dynamic-values-container {
        border-top: 1px solid $twitter-color;
        border-bottom: none;
        display: flex;
        flex-direction: row;

        input {
            width: 70px;
            margin: 0 20px 0 0;
        }
    }

    .exercise-solution {
        border-bottom: none;
    }

    .show-next-part {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;

        .show-next-span {
            border-radius: 10px;
            padding: 3px 10px;
            background-color: rgb(255, 226, 159);

            &:hover {
                cursor: pointer;
            }
        }
    }

    .eot-part {
        border-left: 1px solid rgb(160, 160, 160);
        margin: 10px 0;
        padding-left: 6px;
        position: relative;
    }

    // .show-more-eot-part-dots {
    //     font-weight: bold;
    //     font-size: 150%;
    //     color: rgb(73, 73, 73);
    //     display: inline-block;
    //     transform: translate(-27px, -22px);
    //     border: 1px solid rgb(236, 236, 236);
    //     background: rgb(243, 243, 243);
    //     line-height: 20px;
    //     position: absolute;

    //     &:hover {
    //         cursor: pointer;
    //     }
    // }

    .MJXc-display {
        margin: 0;
    }

    .show-more-box {
        background: rgb(253, 252, 255);

        .comments-part {
            border: 1px solid rgb(255, 230, 255);
            margin-top: 20px;
            padding: 5px 0;

            .comment {
                border: 1px solid rgb(186, 255, 186);
                margin: 10px 0;
            }
        }
    }

    .replys-to-comment,
    .reply-to-comment {
        color: rgb(0, 0, 222);

        &:hover {
            color: rgb(29, 29, 255);
            cursor: pointer;
        }
    }

    .eot-header {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;

        .save-eot {
            border: 1px solid rgb(3, 174, 14);
            padding: 0 3px;
            border-radius: 3px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            height: 32px;
            margin-left: 5px;
            width: 100px;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.modal {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.saved-buckets-list {
    background: white;
    // margin-top: 20px;
    padding: 10px 5px;

    .buckets-filter-box {
        margin: 0 0 20px 0;

        input {
            height: 30px;
            padding: 5px;
            width: 100%;
        }
    }

    .bucket-in-list {
        color: rgb(27, 132, 231);
        padding: 10px;
        margin: 14px 0px;
        font-size: large;
        border: 1px solid rgb(27, 132, 231);
        flex: 1;
        border-radius: 3px;

        &.selected-bucket-success {
            border: 1px solid rgb(0, 175, 0);
            border-left: 3px solid rgb(0, 175, 0);
        }

        &.selected-bucket-error {
            border: 1px solid red;
            border-left: 3px solid red;
        }

        &:hover {
            cursor: pointer;
            background: rgb(249, 252, 255);
        }
    }

    .delete-collection {
        border: 1px solid rgb(235, 23, 23);
        padding: 12px;

        &:hover {
            cursor: pointer;
            background: rgb(247, 219, 219);
        }
    }
}

.contributors-exercise-page {
    .add-field {
        margin-top: 20px;
        margin-right: 20px;
        width: 195px;
        font-weight: bold;
        height: 35px;
        line-height: 0px;
        border-radius: 3px;
        box-shadow: 4px 4px 4px -2px rgb(140, 140, 140);
    }

    .solving-input {
        height: 150px;
    }

    .toggle-span {
        color: rgb(70, 199, 255);
        margin-right: 20px;

        &:hover {
            cursor: pointer;
        }
    }

    .delete-span {
        user-select: none;
        color: red;
        margin-right: 20px;

        &:hover {
            cursor: pointer;
        }
    }

    .contributors-exercise-box {
        margin: 20px 0;
    }

    .rendered-latex-box {
        border: 1px solid rgb(189, 235, 255);
        border-radius: 5px;
        padding: 10px;
    }

    .card-text {
        color: black;
    }

    .card-formula {
        color: black;
    }

    .contributors-page-heder {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgb(177, 177, 177);
        margin-bottom: 20px;

        button {
            width: 150px;
            font-weight: bold;
        }
    }

    .exercise-page {
        margin: 0px;
    }

    .text-solution-border {
        text-align: center;
        margin: 20px 0;
        border-radius: 10px;
        border: 1px solid rgb(255, 201, 140);
        line-height: 17px;
        box-shadow: 3px 3px 9px -2px rgb(255, 201, 140);
    }

    .field-description {
        border-top: 1px solid silver;
        border-left: 1px solid silver;
        border-right: 1px solid silver;
    }

    .comment-in-check-page {
        color: black;
        background: white;
        padding: 5px;
        margin-bottom: 20px;
        border: 1px solid rgb(190, 190, 190);
        border-radius: 3px;

        &.new_notification {
            border-left: 7px solid rgb(238, 0, 255);
        }

        .comment-info {
            margin-right: 20px;
            padding: 5px 0;
            color: rgb(115, 115, 115);
        }

        .content-in-check-page {
            margin-top: 10px;
        }

        &:hover {
            cursor: pointer;
            color: rgb(0, 16, 141);
            background-color: rgb(251, 252, 255);
        }
    }
}

.exercise_from_my_list {
    padding: 10px;
    margin: 30px 0;
    border: 1px solid rgb(179, 221, 236);
    border-left: 1px solid rgb(131, 176, 192);
    border-radius: 3px;
    box-shadow: -1px 5px 15px -1px rgb(166, 200, 214);
    background-color: white;

    &.new_notification {
        border-left: 7px solid rgb(238, 0, 255);
    }

    &:hover {
        cursor: pointer;
        color: rgb(0, 16, 141);
        background-color: rgb(251, 252, 255);
    }

    .text_div {
        color: black;
    }
}

@media screen and (max-width: 991px) {
    .exercise_from_my_list_inner {
        overflow: scroll;
        overflow-y: hidden;
    }

    .exercise_from_my_list_inner::-webkit-scrollbar {
        -webkit-appearance: none;
        height: 5px !important;
    }

    .exercise_from_my_list_inner::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: rgb(27, 132, 231);
    }
}

.manage-theory {
    border: 1px solid red;
    position: absolute;
    width: 100%;
    top: 151px;
    bottom: 0px;

    .all-theories {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        max-width: 46%;
        box-sizing: border-box;
        overflow-y: auto;
        border: 1px solid blue;
        z-index: 888;

        .theory-metadata {
            color: rgb(45, 45, 45);
            margin: 7px 4px;
            border: 1px solid rgb(222, 222, 222);
            border-left-color: rgb(206, 206, 206);
            border-bottom-color: rgb(201, 201, 201);
            border-radius: 3px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            padding: 7px 4px;

            &:hover {
                cursor: pointer;
            }

            &.selected {
                border: 2px solid rgb(39, 157, 0);
            }

            &.already-in {
                border: 1px solid rgb(196, 10, 10);
            }
        }
    }

    .theory-organization {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        max-width: 54%;
        box-sizing: border-box;
        overflow-y: auto;
        border: 1px solid green;
        z-index: 888;

        .as-gr {
            &:hover {
                cursor: pointer;
                color: rgb(102, 0, 0);
            }
        }

        .real-yes {
            color: rgb(41, 41, 41);
            font-size: large;
        }

        .real-no {
            color: rgb(112, 112, 112);
            font-size: medium;

            span {
                margin-left: 10px;

                border: 1px solid blue;
                padding: 3px;
                margin: 0 5px;

                &:hover {
                    cursor: pointer;
                    color: rgb(102, 0, 0);
                }
            }
        }

        .add-top-level-area-d {
            margin: 10px 0;

            .span-btn {
                border: 1px solid blue;
                padding: 3px;
                margin: 0 5px;

                &:hover {
                    cursor: pointer;
                    color: rgb(102, 0, 0);
                }

                -webkit-touch-callout: none;
                /* iOS Safari */
                -webkit-user-select: none;
                /* Safari */
                -khtml-user-select: none;
                /* Konqueror HTML */
                -moz-user-select: none;
                /* Old versions of Firefox */
                -ms-user-select: none;
                /* Internet Explorer/Edge */
                user-select: none;
            }
        }

        .title-and-order-number {
            background: rgb(232, 255, 236);
            margin: 10px 0;
            padding: 10px;

            input {
                width: 480px;
                max-width: 90%;
                margin: 5px 0;
            }
        }
    }
}

.grade-managerr {
    margin: 20px 50px;
    padding: 10px 50px;
    border: 2px solid rgb(156, 0, 183);
}

.verticaly-boxes {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;

    .left-box {
        padding: 50px 10px;
        position: relative;
        width: 63%;
        box-sizing: border-box;
        border: 2px solid blue;
        z-index: 888;
        margin-bottom: 30px;
    }

    .right-box {
        padding: 50px 10px;
        position: relative;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid rgb(0, 180, 0);
        z-index: 888;
        margin-bottom: 30px;
    }
}

.manage-grades {
    .form-add-new-area-in-grade {
        margin: 20px 0;
        border: 2px solid rgb(124, 226, 56);
        padding: 5px;
    }

    .grade-row {
        margin: 5px;
        border: 1px solid rgb(195, 127, 0);
        padding: 3px;
    }

    .grade-org {
        margin-top: 20px;
        padding: 5px;
        border-top: 1px solid rgb(172, 172, 172);
    }

    .span-btn {
        border: 1px solid blue;
        padding: 3px;
        margin: 5px;

        &:hover {
            cursor: pointer;
            color: rgb(102, 0, 0);
        }

        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Safari */
        -khtml-user-select: none;
        /* Konqueror HTML */
        -moz-user-select: none;
        /* Old versions of Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
    }

    .search-isbns-results {
        position: absolute;
        overflow-y: auto;
        width: 100%;
        max-width: 320px;
        max-height: 480px;
        background-color: white;
        z-index: 9;
        border: 1px solid gray;
        margin-top: -1px;
        padding: 5px;

        .selected {
            background-color: lightyellow;
        }
    }

    .rendered-isbn-organization {
        border: 1px solid rgb(202, 202, 202);
        color: rgb(0, 0, 125);
        background-color: rgb(243, 243, 243);
    }
}

.add-isbn-page {
    .chosen-grade {
        color: rgb(0, 76, 163);

        &:hover {
            cursor: pointer;
            color: rgb(102, 0, 0);
        }
    }
}

.search-isbns-results {
    position: absolute;
    overflow-y: auto;
    width: 100%;
    max-width: 320px;
    max-height: 480px;
    background-color: white;
    z-index: 9;
    border: 1px solid gray;
    margin-top: -1px;
    padding: 5px;

    .selected {
        background-color: lightyellow;
    }
}

.find-template-modal {
    max-width: 960px;
    width: 100%;
}

.save-exercise-modal,
.save-theory-modal {
    .search-isbns-results {
        position: absolute;
        overflow-y: auto;
        width: 100%;
        max-width: 320px;
        max-height: 480px;
        background-color: white;
        z-index: 9;
        border: 1px solid gray;
        margin-top: -1px;
        padding: 5px;

        .selected {
            background-color: lightyellow;
        }
    }

    .rendered-isbn-organization {
        margin-top: 10px;
        margin-bottom: 15px;
        border: 1px solid rgb(195, 195, 195);
    }

    .isbn-area-details {
        font-size: large;
        color: black;
    }

    .isbn-area-chose {
        border: 1px solid rgb(255, 157, 92);
        padding: 10px;
        margin: 20px 0;
    }

    .grade-option {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 35px;
    }

    .area-label {
        margin-left: 15px;
    }

    .subarea-label {
        margin-left: 30px;
    }
}

.subjects-authority {
    .subject-authority {
        border: 1px solid blue;
        padding: 5px;
        margin-bottom: 20px;

        &:hover {
            cursor: pointer;
        }
    }
}

.friends-page {
    .search-friend {
        display: flex;
        align-items: center;
        align-items: flex-end;
    }

    .find-friend-btn {
        height: 42px;
        margin-left: 12px;
    }

    .describe-header {
        border: 1px solid rgb(214, 214, 214);
    }

    .accept-reject-friend,
    .delete-friend,
    .cancel-friend-request,
    .add-friend {
        text-align: center;
        border-radius: 3px;
        width: 90px;
        color: black;
        margin-left: 10px;
        padding: 5px;

        &:hover {
            cursor: pointer;
        }
    }

    .accept-friend {
        border: 1px solid rgb(0, 155, 0);
    }

    .add-friend {
        border: 1px solid rgb(40, 97, 255);
    }

    .reject-friend,
    .delete-friend {
        color: black;
        padding: 5px;
        border: 1px solid rgb(234, 0, 0);
    }

    .delete-friend {
        font-size: 95%;
        width: 50px;
    }

    .cancel-friend-request {
        border: 1px solid rgb(255, 212, 22);
    }

    .find-friend-card {
        border: 1px solid rgb(222, 222, 222);
        padding: 5px 5px 5px 8px;
    }

    .list-of-found-friends {
        .col-lg-6 {
            padding: 0;
        }
    }

    .new-notifications-while-in-friends {
        color: rgb(93, 93, 93);
        border: 1px solid rgb(255, 191, 0);
        display: inline-block;
        margin-bottom: 8px;
        padding: 3px;
        border-radius: 3px;

        .refresh-friends {
            color: blue;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.find-theory-page {
    .wrapper {
        margin-top: 20px;
        padding: 10px;
        background: white;
    }

    .real-yes {
        color: rgb(41, 41, 41);
        font-size: x-large;
        margin-bottom: 20px;

        .description {
            font-size: medium;
            color: rgb(89, 89, 89);
        }

        &:hover {
            cursor: pointer;
            color: rgb(102, 0, 0);
        }
    }

    .real-no {
        color: rgb(136, 136, 136);
        font-size: medium;
        margin-bottom: 10px;
    }

    .select-grade {
        border-bottom: 1px solid rgb(220, 220, 220);
        margin-bottom: 20px;
        padding-bottom: 10px;
    }
}

.find-theory {
    .organization {
        .teory-title {
            font-size: 24px;
            color: rgb(46, 46, 46);

            &.real {
                color: rgb(0, 112, 232);

                &:hover {
                    cursor: pointer;
                    color: rgb(4, 158, 213);
                }
            }
        }
    }
}

.comment-box {
    position: relative;

    .show-more-box {
        .form-control {
            border-radius: 5px;
        }

        .comment-and-replies {
            margin-top: 20px;
        }

        .reply-to-comment {
            margin-left: 79px;
        }

        .span-btn {
            border: 1px solid rgb(92, 92, 255);
            padding: 2px;
            margin: 0 5px;
            border-radius: 3px;
            font-size: small;

            &:hover {
                cursor: pointer;
                color: rgb(102, 0, 0);
            }

            -webkit-touch-callout: none;
            /* iOS Safari */
            -webkit-user-select: none;
            /* Safari */
            -khtml-user-select: none;
            /* Konqueror HTML */
            -moz-user-select: none;
            /* Old versions of Firefox */
            -ms-user-select: none;
            /* Internet Explorer/Edge */
            user-select: none;
        }

        .queried-comments {
            .comments {
                padding: 10px 0;
                margin-top: 20px;
                border-top: 1px solid rgb(225, 225, 225);

                .comment-replies {
                    margin-left: 41px;
                }
            }

            .comment {
                border-radius: 3px;
                padding: 5px 0;
                display: flex;
                margin-bottom: 16px;

                .com {
                    width: 100%;
                    margin-left: 8px;
                }

                .com-details {
                    display: flex;
                    flex-direction: column;
                    padding: 1px 5px 5px 5px;
                    background: rgb(239, 239, 239);
                    border-radius: 0 12px 12px 12px;

                    .com-header {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .com-body {
                        color: rgb(52, 52, 52);

                        .tag-in-comment {
                            border: 1px solid rgb(20, 130, 255);
                            color: rgb(20, 130, 255);
                            border-radius: 3px;
                            padding: 2px;
                            background: rgb(230, 241, 255);

                            &:hover {
                                cursor: pointer;
                                color: rgb(5, 13, 91);
                            }
                        }
                    }
                }

                .com-replies {

                    .reply,
                    .n-replies {
                        font-size: smaller;
                        color: blue;

                        &:hover {
                            cursor: pointer;
                            color: rgb(5, 13, 91);
                        }
                    }
                }
            }
        }
    }

    .show-more-eot-part-dots-new {
        font-weight: bold;
        font-size: 150%;
        color: rgb(73, 73, 73);
        display: inline-block;
        transform: translate(-27px, -22px);
        border: 1px solid rgb(200, 200, 200);
        border-radius: 5px;
        background: rgb(243, 243, 243);
        line-height: 20px;
        position: absolute;

        &:hover {
            cursor: pointer;
        }
    }
}

.text-inputs-with-tags {
    height: 200px;
    width: 500px;
    // margin-left: 41px;
    border: 1px solid rgb(193, 193, 193);

    .tag {
        border: 1px solid red;
    }

    .textarea-w-tags {
        border: none;
        resize: none;
    }
}

.content-editable-wrapper {
    //border: 1px solid red;
    margin-bottom: 16px;
    position: relative;

    .content-editable-component {
        border: 1px solid rgb(198, 198, 198);
        padding: 5px;
        color: rgb(59, 59, 59);
        border-radius: 3px;

        .tag {
            border: 1px solid rgb(20, 130, 255);
            color: rgb(20, 130, 255);
            border-radius: 3px;
            padding: 2px;
            background: rgb(230, 241, 255);

            // &:hover {
            //     cursor: pointer;
            // }
        }
    }

    .to-tag-wrapper {
        border: 1px solid rgb(181, 181, 181);
        position: absolute;
        overflow-y: auto;
        width: 100%;
        max-width: 320px;
        max-height: 480px;
        background-color: white;
        z-index: 9;
        margin-top: -1px;
        padding: 5px;

        .to-tag {
            border: 1px solid rgb(154, 154, 255);
            margin-bottom: 5px;
            padding: 3px;

            &:hover {
                cursor: pointer;
                color: rgb(65, 65, 65);
            }

            &.contributor {
                border: 1px solid rgb(255, 100, 66);
            }
        }
    }
}

// this is duplicate. Fix this. I extracted this in order to work "preview"
// on when typing text in "TextInputWithTags.tsx" (still not implemented. 2021.12.26. 21:35 xD)
.com-body {
    color: rgb(52, 52, 52);

    .tag-in-comment {
        border: 1px solid rgb(20, 130, 255);
        color: rgb(20, 130, 255);
        border-radius: 3px;
        padding: 2px;
        background: rgb(230, 241, 255);

        &:hover {
            cursor: pointer;
            color: rgb(5, 13, 91);
        }
    }
}

.span-btn {
    border: 1px solid rgb(92, 92, 255);
    padding: 2px;
    margin: 0 5px;
    border-radius: 3px;
    font-size: small;

    &:hover {
        cursor: pointer;
        color: rgb(102, 0, 0);
    }

    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
}

.hover-pointer {
    &:hover {
        cursor: pointer;
        color: rgb(19, 107, 188);
    }
}

.primary-color {
    color: rgb(27, 132, 231)
}

.h-btn {
    border: 1px solid rgb(163, 163, 255);
    border-left: 4px solid rgb(237, 237, 255);
    padding: 2px;
    margin: 0 5px;
    border-radius: 3px;
    display: inline-block;
    margin-bottom: 6px;

    &:hover {
        cursor: pointer;
        color: rgb(102, 0, 0);
    }
}

.h-has-notificationx {
    border-left: 4px solid red !important;
}

.notifications-page {
    .notification {
        color: black;
        font-size: large;
        border: 1px solid rgb(215, 215, 215);
        margin-bottom: 20px;
        padding: 20px 5px;
        background: white;

        &:hover {
            cursor: pointer;
            color: rgb(0, 16, 141);
            background-color: rgb(251, 252, 255);
        }

        &.new_notification {
            border-left: 7px solid rgb(238, 0, 255);
        }

        .preview_in_notification {
            border-radius: 5px;
            padding: 10px;
            background: rgb(242, 242, 242);
            font-size: 90%;
        }
    }
}

.subject-page {
    margin-top: 20px;

    .choice {
        border: 1px solid rgb(255, 134, 48);
        color: rgb(0, 153, 20);
        margin: 20px 0;
        padding: 10px;

        &:hover {
            cursor: pointer;
            color: rgb(0, 119, 123);
        }
    }
}

.highlighted-exercise-part {
    border: 1px solid rgb(0, 98, 255);
    border-radius: 5px;
    padding: 16px;
    margin: 8px 8px 8px 0;
    display: inline-block;
    color: rgb(184, 0, 208);
    font-size: large;
    font-weight: 600;

    &:hover {
        cursor: pointer;
        color: rgb(124, 6, 167);
        background-color: rgb(248, 248, 254);
    }
}

.card-professor-profile {
    display: flex;
    align-items: center;
    border: 1px solid #ced4da;
    margin-bottom: 16px;
    padding: 20px;

    img {
        width: 80px;
        @include border-radius(100%);
    }

    .card-professor-text {
        margin-left: 20px;
    }

    .card-professor-text h4 {
        color: #4662D4;
        cursor: pointer;

        &:hover {
            color: #2e4cc6;
        }
    }
}

* {
    box-sizing: border-box;
    outline: none !important;
    // text-decoration: none !important;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // height: 100vh;
}

.app-background {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0.15;
        background: linear-gradient(rgba(134, 139, 161, 0.2), rgba(134, 139, 161, 0.2)), url(../../public/landing_bckg.png);
        background-position: center;
        background-repeat: repeat;
        background-attachment: fixed;
        min-height: 100vh;
        z-index: -1;
        height: 100%;
        width: 100%;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.set-footer-to-bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 58px);
}

.custom-header-wrapper {
    max-height: 58px;
    height: 58px;
    background: white;
    border-bottom: 1px solid rgb(223, 223, 223);

    .custom-header {
        display: flex;
        justify-content: space-between;
        height: 58px;
        max-height: 58px;

        min-width: fit-content;
        background-color: white;
        border-bottom: 1px solid rgb(223, 223, 223);

        .custom-header-left {
            display: flex;
            align-items: center;
            height: 58px;
            max-height: 58px;
        }

        .custom-header-right {
            display: flex;
            align-items: center;
            height: 58px;
            max-height: 58px;
        }
    }
}

.mobile-header {
    .sidenav {
        height: 100vh;
        z-index: 1;
        top: 0;
        background-color: white;
        display: flex;
        justify-content: center;
        position: fixed;
        right: -100%;
        transition: 850ms;
        padding-left: 20px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

        .sidenav-links {
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            a {
                padding: 8px 32px 8px 8px;
                text-decoration: none;
                font-size: 25px;
                color: #818181;
                display: block;
                transition: 0.3s
            }
        }


        a:hover, .offcanvas a:focus{
            color: #f1f1f1;
        }

    }

    .active {
        right: 0 !important;
        transition: 2s;
    }

    .navbar-toggler {
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 1000;
    }

    .navbar-toggler span {
        display: block;
        background-color: #4f4f4f;
        height: 3px;
        width: 25px;
        margin-top: 5px;
        margin-bottom: 5px;
        position: relative;
        left: 0;
        opacity: 1;
        transition: all 0.35s ease-out;
        transform-origin: center left;
      }

      .navbar-toggler span:nth-child(1) {
        transform: translate(0%, 0%) rotate(0deg);
      }

      .navbar-toggler span:nth-child(2) {
        opacity: 1;
      }

      .navbar-toggler span:nth-child(3) {
        transform: translate(0%, 0%) rotate(0deg);
      }

      .navbar-toggler span:nth-child(1) {
        margin-top: 0.3em;
      }

      .navbar-toggler:not(.collapsed) span:nth-child(1) {
        transform: translate(15%, -33%) rotate(45deg);
      }

      .navbar-toggler:not(.collapsed) span:nth-child(2) {
        opacity: 0;
      }

      .navbar-toggler:not(.collapsed) span:nth-child(3) {
        transform: translate(15%, 33%) rotate(-45deg);
      }
}

.expand-section-wrapper-to-min-whole-screen {
    min-height: calc(100vh - 58px - 61px - 50px - 12px);
}

.modal-dialog {
    .modal-content {
        max-width: 100%;

        .responsive-modal-box {
            max-width: 100% !important;
        }

         .modal-body {
            max-height: 68vh;
            height: auto;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;

            .ReactCrop, .ReactCrop__child-wrapper {
                max-height: inherit;
            }
        }
    }
}

.eots-and-saved-collections {
    // div::-webkit-scrollbar {
    //     display: none;
    // }

    width: 100%;
    height: 100%;
    // overflow: hidden;
    background-color: rgb(255, 255, 255);

    padding-top: 64px;

    .eots-and-saved-collections-inner {
        // height: 100%;       /* Child can take the full height of the parent */
        // overflow: auto;     /* Allows the child to scroll */
        // -ms-overflow-style: none;  /* Hides scrollbar in Internet Explorer and Edge */
        // scrollbar-width: none;

        .tab-nav {
            height: 64px;
            width: 100%;
            position: fixed;
            top: 0;
            left: 0;
            display: flex;
            background-color: white;
            z-index: 1000;

            .tab-btn {
                display: flex;
                border: 1px solid rgb(27, 132, 231);
                width: 50%;
                height: 100%;
                text-align: center;
                justify-content: center;
                align-items: center;
                background-color: rgb(250, 250, 250);
                color: rgb(121, 121, 121);
                font-size: large;

                &.active {
                    background-color: rgb(27, 132, 231);
                    color: white;
                    font-weight: 500;
                }
            }
        }
    }
}

.no-highlightable-text {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.more-btn-1 {
    position: absolute;
    top: 6px;
    right: 6px;
    background-color: white;
    padding: 0 8px;
    border-radius: 100%;
    font-size: 116%;

    &:hover {
        background-color: rgb(247, 247, 247);
        box-shadow: 0px 0px 9px 1px rgba(217, 217, 217, 1);
    }

    &:active {
        background-color: rgb(243, 243, 243);
        box-shadow: 0px 0px 7px 1px rgb(234, 234, 234);
        border: 1px solid rgb(198, 222, 243);
    }
}

.delete-collection-popover-body {
    .option {
        font-size: 110%;
        margin-bottom: 4px;
        padding: 4px 6px;

        &:hover {
            cursor: pointer;
            background-color: rgb(243, 247, 255);
        }
    }
}

.logo-font {
    font-family: "Cherry Bomb One", system-ui;
    font-weight: 300;
    font-style: normal;
    font-size: 42px;
}

.dropbox {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    height: 200px;
    border: 2px dashed;
    border-radius: 10px;
    background-color: #fafafa;
    color: #6c757d;
    font-size: 1.4rem;
    outline: none;
    transition: border 0.24s ease-in-out;
    margin-bottom: 40px;
    cursor: pointer;

    p {
        margin: 40px 0;
    }
}

.dropbox-profile-image {
    padding: 40px;
    height: 200px;
    width: 200px;
    border: 1px solid #1b84e7;
    border-radius: 50%;
    background-color: #fafafa;
}

@media screen and (max-width: 300px) {
    .logo-font {
        font-size: 80px !important;
    }

    .my-landing-page p {
        font-size: 14px !important;
    }
}

.my-landing-page {
    overflow-y: hidden;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0.2;
        background: url(../../public/landing_bckg.png);
        background-position: center;
        background-repeat: repeat;
        background-attachment: fixed;
        min-height: 100vh;
        z-index: -1;
        height: 100%;
        width: 100%;
    }

    & p {
        font-size: 18px;
    }
}

@include media-breakpoint-up(xs) {
    .img-in-eot-solution {
        max-width: 100% !important;
    }

    .img-in-eot-text {
        max-width: 100% !important;
    }
}

@include media-breakpoint-up(sm) {
    .img-in-eot-solution {
        max-width: 94% !important;
    }

    .img-in-eot-text {
        max-width: 94% !important;
    }
}

@include media-breakpoint-up(md) {
    .img-in-eot-solution {
        max-width: 84% !important;
    }

    .img-in-eot-text {
        max-width: 74% !important;
    }
}

@include media-breakpoint-up(lg) {
    .img-in-eot-solution {
        max-width: 74% !important;
    }

    .img-in-eot-text {
        max-width: 58% !important;
    }
}

@include media-breakpoint-up(xl) {
    .img-in-eot-solution {
        max-width: 64% !important;
    }

    .img-in-eot-text {
        max-width: 48% !important;
    }
}