/* --------------------------------------- */
/* ############### TOOLTIP ############### */
/* --------------------------------------- */

.tooltip-static-demo {
  text-align: center;
  border: 1px solid $gray-400;
  padding: 25px;

  .tooltip {
    z-index: 1;
    opacity: 1;
    position: relative;
    display: inline-block;
    margin: 0 10px;
  }

  .bs-tooltip-top,
  .bs-tooltip-bottom {
    .arrow {
      left: 50%;
      margin-left: -2px;
    }
  }

  .bs-tooltip-left,
  .bs-tooltip-right {
    margin-top: 5px;

    .arrow {
      top: 50%;
      margin-top: -3px;
    }
  }
}
