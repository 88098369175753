
.slim-landing {
  background-color: #fff;
}

.slim-landing-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  .container {
    padding-top: 30px;
    padding-bottom: 30px;

    @include media-breakpoint-up(sm) {
      display:flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.slim-landing-header-left {
  margin-bottom: 15px;

  @include media-breakpoint-up(sm) { margin-bottom: 0; }

  h1 {
    color: #fff;
    margin-bottom: 2px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: -1px;
  }

  p {
    margin-bottom: 0;
    color: rgba(#fff,.75);
  }
}

.slim-landing-header-right {
  a {
    display: inline-block;
    font-size: 24px;
    color: rgba(#fff,.75);

    + a { margin-left: 15px; }

    &:hover,
    &:focus { color: #fff; }
  }
}

.btn-slim-purchase {
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 12px;
  padding: 14px 25px;
  border-color: transparent;
  background-color: #0D0D6B;
  color: #fff;
  display: block;
  margin-bottom: 10px;

  @include media-breakpoint-up(sm) {
    display: inline-block;
    margin-bottom: 0;
  }

  span { opacity: .75; }

  &:hover,
  &:focus {
    color: #fff;
    background-color: darken(#0D0D6B, 2%);
  }
}

.btn-slim-demo {
  border-color: rgba(#fff,.75);
  color: rgba(#fff,.75);
  padding: 14px 25px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 12px;
  display: block;

  @include media-breakpoint-up(sm) {
    margin-left: 5px;
    display: inline-block;
  }

  &:hover,
  &:focus {
    color: #fff;
    border-color: #fff;
  }
}

.slim-landing-headline {
  background-color: $blue;
  @include gradient-x($blue,lighten($darkblue,15%));
  padding-top: 150px;

  @include media-breakpoint-up(sm) { padding-top: 118px; }

  .row {
    align-items: center;
  }

  h1 {
    font-weight: 300;
    font-size: 24px;
    color: #fff;
    line-height: 1.4;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) { font-size: 32px; }

    @include media-breakpoint-up(lg) {
      font-size: 40px;
      line-height: 1.3;
    }
  }

  h5 {
    font-weight: 300;
    font-size: 14px;
    letter-spacing: .5px;
    color: rgba(#fff,.75);
    line-height: 1.5;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) { font-size: 16px; }
  }

  img {
    position: relative;

    @include media-breakpoint-up(lg) { transform: translateX(35px); }
  }
}

.slim-landing-demo {
  padding: 30px 0;
  background-color: $body-bg;

  @include media-breakpoint-up(lg) { padding: 60px 0; }

  h1 {
    text-align: center;
    color: $gray-900;
    font-size: 24px;

    @include media-breakpoint-up(lg) {
      font-size: 32px;
    }
  }

  h6 {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    line-height: 1.6;

    @include media-breakpoint-up(lg) {
      font-size: 16px;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.row-demo-list {
  margin-top: 30px;

  @include media-breakpoint-up(lg) { margin-top: 60px; }

  figure {
    display: block;
    padding: 10px;
    border: 1px solid $gray-300;
    background-color: #fff;
    position: relative;
    margin-bottom: 0;
    @include transition($transition-base);

    &:hover,
    &:focus {
      border-color: transparent;
      figcaption { opacity: 1; }
    }
  }

  figcaption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($gray-900, .35);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    @include transition($transition-base);

    .btn {
      padding-left: 20px;
      padding-right: 20px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 12px;
      border-width: 0;
    }
  }
}

.slim-landing-skin,
.slim-landing-rtl {
  padding: 30px 0;

  @include media-breakpoint-up(lg) { padding: 60px 0; }

  h1 {
    text-align: center;
    color: $gray-900;
    font-size: 24px;

    @include media-breakpoint-up(lg) {
      font-size: 32px;
    }
  }

  h6 {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    line-height: 1.6;

    @include media-breakpoint-up(lg) {
      font-size: 16px;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  figure { margin-bottom: 0; }
}

.slim-landing-skin  { background-color: #fff; }
.slim-landing-rtl { background-color: $body-bg; }

.slim-landing-footer {
  padding: 60px 0;
  background-color: $gray-900;
  text-align: center;

  h1 {
    color: #fff;
    font-weight: 300;
    font-size: 28px;
    margin-bottom: 50px;
  }

  .btn {
    background-color: $blue;
    @include gradient-x($blue,lighten($darkblue,15%));
    color: #fff;
    padding: 15px 25px;
    text-transform: uppercase;
    letter-spacing: 2px;
    border: 0;
    font-size: 12px;

    &:hover,
    &:focus {
      background-color: darken($blue, 2%);
      @include gradient-x(darken($blue, 2%), lighten($darkblue,13%));
    }
  }
}
