/* ---------------------------------------------- */
/* ############### IonRangeSlider ############### */
/* ---------------------------------------------- */

.irs-min, .irs-max {
  font-family: $font-family-roboto;
  @include border-radius(0);
}

.irs-from, .irs-to, .irs-single {
  font-family: $font-family-roboto;
  background-color: theme-color('info');
  @include border-radius(0);
}

.irs-bar {
  background-image: none;
  background-color: theme-color('info');
}

.irs-bar-edge {
  background-image: none;
  background-color: theme-color('info');
  @include border-left-radius(0);
}

.irs-line-left {
  background-image: none;
  background-color: $gray-300;
  @include border-left-radius(0);
}

.irs-line-mid {
  background-image: none;
  background-color: $gray-300;
}

.irs-line-right {
  background-image: none;
  background-color: $gray-300;
  @include border-right-radius(0);
}

.irs-from::after, .irs-to::after,
.irs-single::after {
	border-top-color: theme-color('info');
}

.irs-slider {
  background-image: none;

  &:before {
    content: '';
    width: 2px;
    height: 18px;
    position: absolute;
    background-color: theme-color('info');
    left: 50%;
    margin-left: -1px;
  }

  &:hover,
  &:focus {
    &:before { background-color: darken(theme-color('info'), 12%); }
  }
}


/* #################### COLOR VARIANT SKINS #################### */

.irs-primary { @include irs-color-variant(theme-color('primary')); }
.irs-success { @include irs-color-variant(theme-color('success')); }
.irs-warning { @include irs-color-variant(theme-color('warning')); }
.irs-danger  { @include irs-color-variant(theme-color('danger')); }
.irs-info    { @include irs-color-variant(theme-color('info')); }
.irs-indigo  { @include irs-color-variant($indigo); }
.irs-purple  { @include irs-color-variant($purple); }
.irs-pink    { @include irs-color-variant($pink); }
.irs-teal    { @include irs-color-variant($teal); }
.irs-orange  { @include irs-color-variant($orange); }
.irs-dark    { @include irs-color-variant($gray-800); }


/* #################### MODERN SKIN #################### */

.irs-modern {
  .irs-line-left,
  .irs-line-mid,
  .irs-line-right {
    height: 6px;
    box-shadow: inset -2px 2px 2px 0 rgba(#222, 0.1);
  }

  .irs-bar,
  .irs-bar-edge {
    height: 6px;
    box-shadow: inset 0 1px 1px 0 rgba(#222, 0.1);
  }

  .irs-slider {
    top: 19px;
    background-color: #fff;
    border: 1px solid theme-color('info');
    @include border-radius(0);

    &::before {
      width: 4px;
      height: 9px;
      background-color: #fff;
      border-left: 1px solid theme-color('info');
      border-right: 1px solid theme-color('info');
      margin-left: -2px;
      top: 3px;
    }
  }

  &.irs-primary { @include irs-modern-slider-variant(theme-color('primary')); }
  &.irs-success { @include irs-modern-slider-variant(theme-color('success')); }
  &.irs-warning { @include irs-modern-slider-variant(theme-color('warning')); }
  &.irs-danger { @include irs-modern-slider-variant(theme-color('danger')); }
  &.irs-info { @include irs-modern-slider-variant(theme-color('info')); }
  &.irs-teal { @include irs-modern-slider-variant($teal); }
  &.irs-indigo { @include irs-modern-slider-variant($indigo); }
  &.irs-purple { @include irs-modern-slider-variant($purple); }
  &.irs-pink { @include irs-modern-slider-variant($pink); }
  &.irs-orange { @include irs-modern-slider-variant($orange); }
  &.irs-dark { @include irs-modern-slider-variant($gray-800); }

}


/* #################### OUTLINE SKIN #################### */
.irs-outline {
  .irs-line {
    border: 1px solid theme-color('info');
    height: 10px;
    @include border-radius(5px);
  }

  .irs-line-left,
  .irs-line-mid,
  .irs-line-right {
    height: 10px;
    background-color: transparent;
  }

  .irs-bar,
  .irs-bar-edge { height: 10px; }

  .irs-bar-edge { @include border-left-radius(5px); }

  .irs-slider {
    top: 21px;
    width: 18px;
    background-color: #fff;
    border: 1px solid theme-color('info');
    @include border-radius(100%);

    &::before {
      margin-left: -4px;
      top: 4px;
      width: 8px;
      height: 8px;
      @include border-radius(100%);
    }

    &:hover,
    &:focus {
      &::before { background-color: darken(theme-color('info'), 5%); }
    }
  }

  &.irs-primary { @include irs-outline-variant(theme-color('primary')); }
  &.irs-success { @include irs-outline-variant(theme-color('success')); }
  &.irs-warning { @include irs-outline-variant(theme-color('warning')); }
  &.irs-danger { @include irs-outline-variant(theme-color('danger')); }
  &.irs-info { @include irs-outline-variant(theme-color('info')); }
  &.irs-teal { @include irs-outline-variant($teal); }
  &.irs-orange { @include irs-outline-variant($orange); }
  &.irs-indigo { @include irs-outline-variant($indigo); }
  &.irs-purple { @include irs-outline-variant($purple); }
  &.irs-pink { @include irs-outline-variant($pink); }
  &.irs-dark { @include irs-outline-variant($gray-800); }
}
