/* --------------------------------------- */
/* ############### POPOVER ############### */
/* --------------------------------------- */

.popover-static-demo {
  border: 1px solid $gray-400;
  background-color: $gray-100;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;

  .popover {
    z-index: 1;
    opacity: 1;
    position: relative;
    display: inline-block;
    margin: 0 10px;
  }

  .bs-popover-top,
  .bs-popover-bottom {
    .arrow {
      left: 50%;
      margin-left: -5px;
    }
  }

  .bs-popover-left,
  .bs-popover-right {
    margin-top: 5px;

    .arrow {
      top: 50%;
      margin-top: -5px;
    }
  }
}
