/* ----------------------------------------- */
/* ############### DASHBOARD ############### */
/* ----------------------------------------- */

.dash-headline {
  @include media-breakpoint-up(lg) {
    display: flex;
    height: 500px;
    position: relative;
  }
}

.dash-headline-left {
  @include media-breakpoint-up(lg) {
    width: 50%;
    height: 100%;
    padding-right: 10px;
  }
}

.dash-headline-item-one {
  height: 400px;
  position: relative;
  background-color: #fff;
  border: 1px solid $card-border-color;
  display: flex;
  align-items: flex-end;

  @include media-breakpoint-up(lg) {
    height: 100%;
  }

  .dash-item-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 25px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;


    @include media-breakpoint-up(sm) {
      padding: 40px;
    }
  }

  h1 {
    font-size: 56px;
    font-weight: 400;
    font-family: $font-family-lato;
    color: $gray-800;
    margin-bottom: 20px;

    @include media-breakpoint-up(sm) {
      font-size: 64px;
    }
  }

  .earning-label {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    color: $gray-800;
    margin-bottom: 15px;

    @include media-breakpoint-up(sm) {
      font-size: 14px;
    }
  }

  .earning-desc {
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
      width: 70%;
      margin-bottom: 30px;
    }
  }

  .statement-link {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1px;
    color: $blue;
    border: 1px solid $blue;
    width: 173px;
    padding: 10px 15px;

    &:hover,
    &:focus { color: darken($blue, 5%); }
  }

  .dash-chartist {
    width: 100%;
    height: 200px;

    @include media-breakpoint-up(sm) {
      height: 100%;
    }

    .ct-labels { display: none; }
    .ct-grid { display: none; }
    .ct-line { stroke-width: 1px; }
    .ct-point { stroke-width: 6px; }

    .ct-series-a {
      .ct-area {
        fill: $blue;
        fill-opacity: .2;
      }

      .ct-line { stroke: darken($blue, 10%); }
      .ct-point { stroke: darken($blue, 10%); }
    }

    .ct-series-b {
      .ct-area {
        fill: $purple;
        fill-opacity: .3;
      }

      .ct-line { stroke: darken($purple, 10%); }
      .ct-point { stroke: darken($purple, 10%); }
    }
  }
}

.dash-headline-right {
  margin-top: 20px;

  @include media-breakpoint-up(lg) {
    width: 50%;
    height: 100%;
    padding-left: 10px;
    margin-top: 0;
  }
}

.dash-headline-right-top {
  height: 50%;
  padding-bottom: 10px;
}

.dash-headline-item-two {
  height: 350px;
  position: relative;
  background-color: #fff;
  border: 1px solid $card-border-color;
  display: flex;
  align-items: flex-end;

  @include media-breakpoint-up(sm) { height: 300px; }
  @include media-breakpoint-up(lg) { height: 100%; }

  .chart-rickshaw {
    width: 100%;
    height: 200px;

    @include media-breakpoint-up(sm) { height: 100%; }
  }

  .dash-item-overlay {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 25px;

    @include media-breakpoint-up(sm) {
      width: 50%;
      padding: 0;
      justify-content: center;
    }
  }

  h4 {
    color: $gray-800;
    font-size: 32px;
    font-family: $font-family-lato;
    font-weight: 700;
  }

  .item-label {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 1px;
    color: $gray-800;
    margin-bottom: 10px;
  }

  .item-desc { font-size: 13px; }

  .report-link {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1px;
  }
}

.dash-headline-right-bottom {
  position: relative;
  padding-top: 10px;

  @include media-breakpoint-up(sm) {
    display: flex;
    height: 300px;
  }

  @include media-breakpoint-up(lg) { height: 50%; }
}

.dash-headline-right-bottom-left {
  @include media-breakpoint-up(sm) {
    width: 50%;
    height: 100%;
    padding-right: 10px;
  }
}

.dash-headline-item-three {
  height: 280px;
  position: relative;
  background-color: #fff;
  border: 1px solid $card-border-color;
  display: flex;
  align-items: flex-end;

  @include media-breakpoint-up(sm) {
    height: 100%;
    justify-content: center;
  }

  > div {
    color: $gray-800;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px;

    @include media-breakpoint-up(sm) {
      text-align: center;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  h1 {
    font-weight: 400;
    font-size: 48px;
    font-family: $font-family-lato;
    margin-bottom: 10px;
  }

  .item-label {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
  }

  .item-desc {
    margin-bottom: 0;
    color: $body-color;
    font-size: 12px;
  }

  .sparkline {
    position: relative;
    bottom: -3px;
  }
}

.dash-headline-right-bottom-right {
  margin-top: 20px;
  @include media-breakpoint-up(sm) {
    width: 50%;
    height: 100%;
    padding-left: 10px;
    margin-top: 0;
  }
}


.card-dash-one {
  .row {
    > div {
      padding: 20px;
      display: flex;
      align-items: center;

      + div {
        border-top: 1px solid $gray-300;

        @include media-breakpoint-up(lg) {
          border-left: 1px solid $gray-300;
          border-top: 0;
        }
      }

      &:nth-child(odd) { background-color: #fcfcfc; }
      &:nth-child(even) { background-color: $gray-100; }
    }
  }

  .icon {
    font-size: 72px;
    color: $gray-500;

    &,
    &::before,
    &::after {
      line-height: 1;
    }
  }

  .dash-content {
    margin-left: 15px;

    label {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 1px;
      margin-bottom: 2px;
    }

    h2 {
      color: $gray-800;
      font-weight: 700;
      font-family: $font-family-lato;
      margin-bottom: 0;
    }
  }
}

.card-customer-overview {
  .card-header {
    padding: 20px;
    background-color: transparent;

    @include media-breakpoint-up(sm) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .slim-card-title { margin-bottom: 0; }

    .nav {
      margin-top: 10px;
      @include media-breakpoint-up(sm) { margin-top: 0; }
    }

    .nav-link {
      position: relative;
      padding: 0;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      color: $body-color;

      &.active { color: theme-color('primary'); }

      + .nav-link {
        &::before {
          content: '';
          top: 0;
          left: 0;
          height: 100%;
          border-left: 1px solid $gray-300;
          margin: 0 15px;
        }
      }
    }
  }

  .card-body { padding-top: 0; }
}


.card-status {
  padding: 20px;

  .media { align-items: center; }
  .media-body { margin-left: 15px; }
  p { margin-bottom: 0; }

  h1 {
    margin-bottom: 0;
    color: $gray-800;
    font-family: $font-family-lato;
    font-weight: 700;
  }

  .icon {
    font-size: 64px;
    &,&::before,&::after { line-height: 1; }
  }
}

.dashboard-3 {
  .slim-pagetitle { line-height: 1.5; }
  .card-activities {
    @include media-breakpoint-up(lg) { height: 435px; }
    @include media-breakpoint-up(xl) { height: 435px; }
  }

  .dash-headline-item-one {
    height: 435px;

    @include media-breakpoint-up(md) { height: 473px; }
    @include media-breakpoint-up(lg) { height: 435px; }

    .chart-rickshaw {
      width: 100%;
      height: 250px;
    }

    .dash-item-overlay {
      padding: 25px;

      h1 {
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .earning-label { margin-bottom: 10px; }

      .earning-desc {
        margin-bottom: 15px;
        @include media-breakpoint-up(lg) { width: 100%; }
      }

      .statement-link {
        padding: 0;
        border-width: 0;
        font-size: 12px;
        letter-spacing: 1px;
      }
    }
  }
}


.card-dash-headline {
  padding: 25px;
  text-align: center;

  @include media-breakpoint-up(sm) { padding: 46px 45px 47px; }
  @include media-breakpoint-up(xl) { padding: 60px; }

  h4 {
    line-height: 1.3;
    color: $gray-800;
    margin-bottom: 20px;
  }

  p { margin-bottom: 30px; }
}

.card-earning-summary {
  padding: 25px;

  h6 {
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1px;
    margin-bottom: 5px;
  }

  h1 {
    color: $gray-800;
    font-family: $font-family-lato;
    font-weight: 700;
    margin-bottom: 3px;
  }

  span {
    font-size: 12px;
    color: $gray-500;
  }
}

.card-dash-chart-one {
  .row > div:first-child {
    border-bottom: 1px solid $gray-400;

    @include media-breakpoint-up(lg) {
      border-bottom: 0;
      border-right: 1px solid $gray-400;
    }
  }

  .left-panel,
  .right-panel { padding: 25px; }

  .left-panel {
    .nav { margin-bottom: 40px; }

    .nav-link {
      padding: 0;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 1px;
      color: $gray-700;

      &:hover,
      &:focus { color: theme-color('primary'); }

      &.active {
        color: theme-color('primary');
      }

      + .nav-link::before {
        content: '';
        margin: 0 10px;
        border-left: 1px solid $gray-400;
      }
    }
  }

  .right-panel {
    .slim-card-title {
      font-family: $font-family-base;
      letter-spacing: 1px;
      font-size: 12px;
      margin-bottom: 25px;
    }
  }

  .active-visitor-wrapper {

    h1 {
      font-size: 96px;
      font-weight: 300;
      color: $gray-800;
      font-family: $font-family-lato;
      margin-bottom: 5px;
      line-height: 1;
    }

    p {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 12px;
    }
  }

  .visitor-operating-label {
    color: $gray-800;
    margin: 25px 0;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 700;
  }
}


.dashboard-4 {
  .card-table {
    .card-header {
      @include media-breakpoint-up(sm) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .nav {
        margin-top: 10px;
        @include media-breakpoint-up(sm) { margin-top: 0; }
      }

      .nav-link {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        color: $gray-800;
        letter-spacing: .5px;
        padding: 0;

        + .nav-link::before {
          content: '';
          margin-left: 10px;
          padding-left: 10px;
          border-left: 1px solid $gray-300;
        }

        &.active { color: theme-color('primary'); }
      }
    }

    .table { min-width: 960px; }
  }
}


.dash-headline-two {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.d-h-t-right {
  display: flex;
  align-items: center;

  .summary-item {
    display: flex;
    align-items: center;
    position: relative;

    + .summary-item {
      margin-left: 40px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -20px;
        bottom: 0;
        border-left: 1px solid $gray-400;
      }
    }

    h1 {
      margin-bottom: 0;
      color: $gray-800;
      font-family: $font-family-lato;
      font-weight: 700;
    }

    span {
      font-size: 11px;
      margin-left: 10px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }
}


.dashboard-chart-one {
  margin-bottom: 35px;

  > div + div {
    .card { margin-left: -1px; }
  }

  .card {
    padding: 25px;
    position: relative;
    height: 100%;
  }

  .card-total {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    h1 {
      font-size: 64px;
      font-family: $font-family-lato;
      font-weight: 700;
      color: $gray-800;
      text-align: center;
      line-height: 1;
      margin-bottom: 15px;
    }

    p {
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 1px;
      text-align: center;
    }
  }

  .card-revenue {
    h6 {
      font-size: 20px;
      font-weight: 500;
      color: $gray-800;
      margin-bottom: 3px;
    }

    h1 {
      font-family: $font-family-lato;
      color: $gray-800;
      font-size: 40px;
      font-weight: 700;
      display: flex;
      align-items: center;
      letter-spacing: -1px;
      margin-bottom: 25px;

      span {
        font-size: 14px;
        font-family: $font-family-base;
        font-weight: 400;
        margin-left: 10px;
        letter-spacing: normal;
      }
    }

    label {
      display: block;
      text-align: center;
      margin-bottom: 0;

      span {
        color: $gray-800;
        font-weight: 700;
        font-family: $font-family-lato;
      }
    }
  }
}

.nav-statistics-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid $gray-400;
  padding: 10px 0;
  margin-bottom: 40px;

  .nav {
    &:first-child {
      .nav-link {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1px;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          bottom: -13px;
          left: 0;
          right: 0;
          border-bottom: 2px solid $gray-800;
          display: none;
        }

        &.active::before { display: block; }
      }
    }
  }

  .nav-link {
    padding: 0;
    color: $body-color;

    + .nav-link { margin-left: 20px; }

    &.active { color: $gray-800; }
  }
}


.row-statistics {
  > div {
    &:first-child {
      display: flex;
      justify-content: center;
      flex-direction: column;

      a {
        color: $body-color;
        display: inline-block;

        &:hover,
        &:focus { color: $gray-800; }

        + a { margin-left: 15px; }
      }
    }
  }
}

.report-summary-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  margin-bottom: 25px;

  > div {
    &:last-child {
      display: flex;
      align-items: center;

      .btn {
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        align-items: center;
        height: 40px;

        .icon {
          line-height: 1;
          margin-right: 10px;
        }

        + .btn { margin-left: 5px; }
      }
    }
  }
}


.card-popular-product {
  padding: 20px;

  .prod-id {
    margin-bottom: 3px;
    display: block;
    font-size: 12px;
  }

  .prod-name {
    font-size: 16px;
    margin-bottom: 3px;
    a { color: $gray-900; }
  }

  .prod-by { font-size: 12px; }

  .row {
    > div {
      &:last-child {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: -1px;
          bottom: 0;
          border-left: 1px solid $gray-400;
        }
      }
    }

    p { margin-bottom: 0; }
  }

  h1 {
    color: $gray-900;
    font-family: $font-family-lato;
    font-weight: 700;
    margin-bottom: 0;
    letter-spacing: -1px;
  }
}


/********** RTL SUPPORT **********/
/*********************************/
[dir="rtl"] {
  .dash-headline-left {
    @include media-breakpoint-up(lg) {
      padding-right: 0;
      padding-left: 10px;
    }
  }

  .dash-headline-right {
    @include media-breakpoint-up(lg) {
      padding-left: 0;
      padding-right: 10px;
    }
  }

  .dash-headline-right-bottom-left {
    @include media-breakpoint-up(sm) {
      padding-right: 0;
      padding-left: 10px;
    }
  }

  .dash-headline-item-one { direction: ltr; }

  .dash-headline-item-three {
    > div {
      left: auto;
      right: 0;
    }
  }

  .dash-headline-right-bottom-right {
    @include media-breakpoint-up(sm) {
      padding-left: 0;
      padding-right: 10px;
    }
  }

  .card-dash-one {
    .row {
      > div {
        + div {
          @include media-breakpoint-up(lg) {
            border-left: 0;
            border-right: 1px solid $gray-300;
          }
        }
      }
    }

    .dash-content {
      text-align: right;
      margin-left: 0;
      margin-right: 15px;
    }
  }

  .card-customer-overview {
    .card-header {
      .nav-link {
        + .nav-link {
          &::before { display: none; }
          &::after {
            content: '';
            margin: 0 10px;
            border-right: 1px solid $gray-300;
          }
        }
      }
    }
  }

  .card-dash-chart-one {
    .row > div:first-child {
      @include media-breakpoint-up(lg) {
        border-right: 0;
        border-left: 1px solid $gray-400;
      }
    }

    .left-panel {
      .nav-link {
        + .nav-link::before { display: none; }
        + .nav-link::after {
          content: '';
          margin: 0 10px;
          border-right: 1px solid $gray-400;
        }
      }
    }
  }

  .dashboard-4 {
    .card-table {
      .card-header {
        .nav-link {
          + .nav-link::before { display: none; }
          + .nav-link::after {
            content: '';
            margin: 0 10px;
            border-right: 1px solid $gray-300;
          }
        }
      }
    }
  }

  .d-h-t-right {
    .summary-item {
      + .summary-item {
        margin-left: 0;
        margin-right: 40px;

        &::before {
          left: auto;
          right: -20px;
          border-left: 0;
          border-right: 1px solid $gray-400;
        }
      }

      span {
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }

  .nav-statistics-wrapper {
    .nav-link {
      + .nav-link {
        margin-left: 0;
        margin-right: 20px;
      }
    }
  }

  .row-statistics > div:first-child {
    text-align: right;

    a + a {
      margin-left: 0;
      margin-right: 15px;
    }
  }

  .report-summary-header > div:last-child .btn {
    .icon {
      margin-right: 0;
      margin-left: 10px;
    }

    + .btn {
      margin-left: 0;
      margin-right: 5px;
    }
  }

  .dashboard-chart-one > div + div .card {
    margin-left: 0;
    margin-right: -1px;
  }

  .card-revenue,
  .card-total {
    text-align: right;
  }

  .card-popular-product {
    text-align: right;

    .row > div:last-child::before {
      left: auto;
      right: -1px;
      border-left: 0;
      border-right: 1px solid $gray-300;
    }
  }
}
