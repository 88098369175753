/* ------------------------------------------ */
/* ############### DataTables ############### */
/* ------------------------------------------ */

table.dataTable {
  border: 1px solid $gray-300;
  margin-bottom: 15px;

  thead {
    th, td {
      padding: 0.75rem;
      border-bottom: 1px solid $gray-300;
      position: relative;

      &.sorting,
      &.sorting_asc,
      &.sorting_desc {
        &::after {
          content: '';
          border: 4px solid transparent;
          border-top-color: $gray-400;
          position: absolute;
          z-index: 10;
          top: 22px;
          right: 8px;
        }
      }

      &.sorting,
      &.sorting_asc,
      &.sorting_desc {
        &::before {
          content: '';
          border: 4px solid transparent;
          border-bottom-color: $gray-400;
          position: absolute;
          z-index: 10;
          top: 12px;
          right: 8px;
        }
      }

      &.sorting_asc::before { border-bottom-color: $gray-800; }
      &.sorting_desc::after { border-top-color: $gray-800; }
    }
  }

  tbody {
    th, td { padding: 0.75rem; }
  }

  &.row-border,
  &.display {
    tbody {
      th, td { border-top-color: $gray-300; }
    }
  }

  &.no-footer { border-bottom-color: $gray-300; }
}

// Number of page filtering wrapper found at top left of datatable
.dataTables_length {
  padding-bottom: 10px;

  .select2-container {
    width: 60px;
    margin-left: 0;
    margin-right: 10px;
  }
}

// Search wrapper found at top right of datatable
.dataTables_filter {
  padding-bottom: 10px;
  padding-right: 5px;

  input {
    @extend .form-control;
  }
}

// Pagination
.dataTables_wrapper .dataTables_paginate {
  .paginate_button {
    padding-top: 0.54rem;
    padding-bottom: 0.54rem;
    background-color: $gray-200;
    border-color: transparent;
    border-radius: 0;

    &:hover,
    &:focus {
      background-color: $gray-400;
      background-image: none;
      border-color: transparent;
      color: $gray-800 !important;
    }

    &:active { box-shadow: none; }

    &.current {
      &,&:hover,&:focus {
        background-color: theme-color('primary');
        background-image: none;
        border-color: transparent;
        color: #fff !important;
      }
    }

    &.disabled {
      &,&:hover,&:focus {
        background-color: $gray-200;
        color: $gray-500 !important;
      }
    }
  }
}

/* ############### RTL SUPPORT ############### */
.rtl {
  table.dataTable {
    thead {
      th, td {
        &.sorting,
        &.sorting_asc,
        &.sorting_desc {
          &::after {
            right: auto;
            left: 8px;
          }
        }

        &.sorting,
        &.sorting_asc,
        &.sorting_desc {
          &::before {
            right: auto;
            left: 8px;
          }
        }
      }
    }
  }

  // Number of page filtering wrapper found at top left of datatable
  .dataTables_length {
    .select2-container {
      margin-right: 0;
      margin-left: 10px;
    }
  }

  // Search wrapper found at top right of datatable
  .dataTables_filter {
    padding-right: 0;
    padding-left: 5px;
  }
}
