/* -------------------------------------------- */
/* ############### FORM LAYOUTS ############### */
/* -------------------------------------------- */

.form-card-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $gray-300;
  height: 500px;
  padding-left: 20px;
  padding-right: 20px;

  @media (min-width: 480px) { padding: 0; }
}
