/* ------------------------------------------ */
/* ############### PAGINATION ############### */
/* ------------------------------------------ */

.pagination-wrapper {
  height: 80px;
  border: 1px solid $gray-400;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}
