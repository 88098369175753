/* --------------------------------------- */
/* ############### Buttons ############### */
/* --------------------------------------- */

.btn {
  border-radius: 0;
  &.rounded-circle { @include border-radius(100%); }
}

.btn:active { box-shadow: none; }

.btn-warning { color: #fff !important; }
.btn-light {
  border-color: $gray-400;
  color: $body-color;

  &:hover,
  &:focus { background-color: #fff; }
}

.btn-indigo { @include button-variant($indigo, darken($indigo, 5%)); }
.btn-purple { @include button-variant($purple, darken($purple, 5%)); }
.btn-pink { @include button-variant($pink, darken($pink, 5%)); }
.btn-teal { @include button-variant($teal, darken($teal, 5%)); }
.btn-slim { @include button-variant($slim-primary, darken($slim-primary, 5%)); }

.btn-outline-light { @include button-outline-variant($gray-500, #fff); }
.btn-outline-indigo { @include button-outline-variant($indigo, #fff); }
.btn-outline-purple { @include button-outline-variant($purple, #fff); }
.btn-outline-pink { @include button-outline-variant($pink, #fff); }
.btn-outline-teal { @include button-outline-variant($teal, #fff); }

.btn-oblong { @include border-radius(50px); }

.btn-uppercase,
.btn-uppercase-sm {
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.btn-uppercase {
  font-size: 12px;
  line-height: 1.434;
}

.btn-uppercase-sm {
  font-size: 11px;
  line-height: 1.564;
}

.btn-with-icon {
  padding: 0;
  display: inline-block;
  border-width: 0;

  > div {
    position: relative;
    display: flex;

    > span {
      display: flex;
      height: 100%;
      align-items: center;

      &.icon {
        justify-content: center;
        background-color: rgba(#000, 0.2);
      }
    }
  }
}

.btn-icon {
  display: inline-block;
  padding: 0;

  > div {
    display: flex;
    width: 35px;
    height: 35px;
    align-items: center;
    justify-content: center;
  }
}

.btn-group { direction: ltr; }
