/* ---------------------------------------- */
/* ############### Chartist ############### */
/* ---------------------------------------- */

// Variables
$color-a:  #ED1E79;
$color-b:  #93268F;
$color-c:  #818AC3;
$color-d:  #0082CD;
$color-e:  #EC7A9C;

// Mixins
@mixin set-series-color($color) {
  .ct-point,
  .ct-line,
  .ct-bar,
  .ct-slice-donut {
    stroke: $color;
  }

  .ct-slice-pie,
  .ct-slice-donut-solid {
    fill: $color;
    stroke: #fff;
  }
}

// Styles
.slim-chartist,
.slim-chartist-dark {
  .ct-label { font-size: 11px; }
  .ct-line { stroke-width: 1px; }
  .ct-point { stroke-width: 8px; }

  .ct-chart-pie,
  .ct-chart-donut {
    .ct-label {
      font-size: 16px;
      fill: rgba(#fff, 0.75);
    }
  }
}

.slim-chartist,
.slim-chartist-dark {
  .ct-series-a { @include set-series-color($color-a); }
  .ct-series-b { @include set-series-color($color-b); }
  .ct-series-c { @include set-series-color($color-c); }
  .ct-series-d { @include set-series-color($color-d); }
  .ct-series-e { @include set-series-color($color-e); }
}

.slim-chartist-dark {
  .ct-grid { stroke: rgba(#fff, .15); }
  .ct-label { color: $body-color; }
  .ct-series-a,
  .ct-series-b,
  .ct-series-c,
  .ct-series-d,
  .ct-series-e {
    .ct-slice-pie { stroke: #212529; }
  }
}
