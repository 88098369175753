/* ------------------------------------------ */
/* ############### Navigation ############### */
/* ------------------------------------------ */

// Nav
.nav-tabs {
  .nav-link { border-radius: 0; }
}

.nav-tabs-for-dark {
  .nav-link {
    color: rgba(#fff, .8);
    &.active { border-color: transparent; }

    &:hover,
    &:focus { color: #fff; }
  }
}


// Nav Custom Color
.nav-gray-600 { @include nav-color-variant($gray-600, $gray-800); }
.nav-gray-700 { @include nav-color-variant($gray-700, $gray-900); }
.nav-white-800 { @include nav-color-variant(rgba(#fff, .8), #fff); }

.nav-pills {
  @include nav-color-variant($gray-700, $gray-800, #fff, transparent, theme-color('primary'));

  .nav-link { border-radius: 0; }
}

.nav-pills-for-dark { @include nav-color-variant(rgba(#fff,.7), #fff, #fff, transparent, theme-color('primary')); }

.nav.active-primary .nav-link.active { color: theme-color('primary'); }
.nav.active-success .nav-link.active { color: theme-color('success'); }
.nav.active-warning .nav-link.active { color: theme-color('warning'); }
.nav.active-danger .nav-link.active { color: theme-color('danger'); }
.nav.active-info .nav-link.active { color: theme-color('info'); }
.nav.active-indigo .nav-link.active { color: $indigo; }
.nav.active-purple .nav-link.active { color: $purple; }
.nav.active-pink .nav-link.active { color: $pink; }
.nav.active-teal .nav-link.active { color: $teal; }
.nav.active-orange .nav-link.active { color: $orange; }

.nav-underline,
.nav-underline-dark {
  .nav-item { padding: 0 20px; }

  .nav-link {
    position: relative;
    padding-left: 0;
    padding-right: 0;

    &::before {
      content: '';
      position: absolute;
      bottom: -11px;
      left: 0;
      width: 100%;
      height: 3px;
      display: none;
    }

    &.active::before { display: block; }
  }
}

.nav-underline {
  .nav-link {
    color: $body-color;

    &::before { background-color: $gray-800; }
    &.active { color: $gray-800; }
  }
}

.nav-underline-dark {
  .nav-link {
    color: rgba(#fff, .6);

    &::before { background-color: #fff; }
    &.active { color: #fff; }
  }
}


/********** RTL SUPPORT **********/
/*********************************/
[dir="rtl"] {
  .nav {
    padding: 0;
    &.flex-column { text-align: right; }
  }
}
