/* ----------------------------------------- */
/* ############### TagsInput ############### */
/* ----------------------------------------- */

.bootstrap-tagsinput {
  border-color: $input-border-color;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0 6px 5px;
  min-height: 40px;
  display: block;

  .label {
    background-color: theme-color('primary');
    color: #fff;
    font-size: $font-size-sm;
    padding: 2px 8px;
    margin-top: 6px;
    display: inline-block;
  }

  input { padding-top: 8px; }

  .tag [data-role="remove"] {
    opacity: .75;

    &::after {
      content: '\f2d7';
      font-family: 'Ionicons';
      font-size: 11px;
    }

    &:hover,
    &:focus,
    &:active {
      opacity: 1;
      box-shadow: none;
    }
  }
}
