/* ------------------------------------------ */
/* ############### FULL WIDTH ############### */
/* ------------------------------------------ */

.slim-full-width {
  .container { max-width: none; }

  .slim-navbar .sub-item {
    @include media-breakpoint-up(xl) { width: 100%; }
  }
}
