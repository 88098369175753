/* ---------------------------------------- */
/* ############### MESSAGES ############### */
/* ---------------------------------------- */

.container-messages {
  position: absolute;
  top: 152px;
  bottom: 0;

  @include media-breakpoint-up(sm) {
    left: 50%;
    width: 510px;
    margin-left: -(510px / 2);
  }

  @include media-breakpoint-up(md) {
    width: 690px;
    margin-left: -(690px / 2);
  }

  @include media-breakpoint-up(lg) {
    width: 930px;
    margin-left: -(930px / 2);
  }

  @include media-breakpoint-up(xl) {
    width: 1110px;
    margin-left: -(1110px / 2);
  }

  .slim-pageheader {
    flex-direction: row;
  }
}

.messages-left {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;

  @include media-breakpoint-up(lg) {
    width: 350px;
  }

  .slim-pageheader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;

    @include media-breakpoint-up(sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.messages-compose {
  font-size: 24px;
  .icon {
    &,
    &::before,
    &::after { line-height: 0; }
  }
}

.messages-list {
  position: absolute;
  top: 65px;
  bottom: 39px;
  left: 0;
  width: 100%;
  overflow-y: auto;
  border-top: 1px solid $gray-400;

  @include media-breakpoint-up(sm) {
    bottom: 90px;
    border: 1px solid $gray-400;
    border-left: 0;
  }

  .media {
    padding: 15px;
    background-color: $gray-100;
    position: relative;
    border-left: 1px solid $gray-400;

    + .media { border-top: 1px solid $gray-300; }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -1px;
      width: 2px;
      background-color: theme-color('primary');
      opacity: 0;
      @include transition($transition-base);
    }

    &:hover,
    &:focus {
      background-color: darken($gray-100, 2%);

      &::before { opacity: 1; }
    }

    &.unread {
      background-color: #fff;
      .media-left .square-10 { border-color: #fff; }
    }
  }

  .media-left {
    position: relative;

    img {
      width: 55px;
      @include border-radius(100%);
    }

    .square-10 {
      border: 2px solid $gray-200;
      @include border-radius(100%);
      position: absolute;
      bottom: 0;
      right: 5px;
    }
  }

  .media-body {
    margin-left: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    > div {
      &:last-child {
        min-width: 50px;
        text-align: right;
        display: flex;
        align-items: flex-end;
        flex-direction: column;

        span:nth-child(2) {
          background-color: theme-color('primary');
          color: #fff;
          @include border-radius(100%);
          font-size: 9px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 18px;
          height: 18px;
          margin-top: 8px;
        }
      }
    }

    h6 {
      font-size: 14px;
      color: $gray-700;
      margin-bottom: 5px;
    }

    p {
      margin-bottom: 0;
      color: $gray-600;
      font-size: 13px;
    }

    span {
      font-size: 12px;
      color: $gray-500;
      display: block;
    }
  }
}

.messages-left-footer {
  position: absolute;
  bottom: 0;
  width: 100%;

  @include media-breakpoint-up(sm) {
    padding-bottom: 25px;
  }
}

.messages-right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;

  @include media-breakpoint-up(sm) {
    top: 25px;
    bottom: 25px;
    border: 1px solid $gray-400;
  }

  @include media-breakpoint-up(lg) {
    left: 375px;
  }
}

.message-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  border-bottom: 1px solid $gray-300;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 15px;

  @include media-breakpoint-up(lg) { padding: 0 20px; }

  .media {
    align-items: center;

    img {
      width: 50px;
      @include border-radius(100%);
    }

    .media-body {
      margin-left: 15px;
      h6 {
        font-size: 15px;
        margin-bottom: 5px;
        color: $gray-800;
      }

      p {
        font-size: 12px;
        margin-bottom: 0;
      }
    }
  }

  .message-back {
    margin-right: 15px;
    font-size: 30px;

    i { line-height: 0; }

    @include media-breakpoint-up(lg) { display: none; }
  }

  .message-option {
    margin-left: auto;
    display: flex;
    align-items: center;

    a {
      display: block;
      font-size: 26px;
      margin-left: 15px;
    }
  }
}

.message-body {
  position: absolute;
  top: 80px;
  bottom: 65px;
  left: 0;
  width: 100%;
  overflow-y: auto;
  background-color: $gray-100;

  .media-list {
    .media {
      padding: 15px;
      @include media-breakpoint-up(lg) { padding: 15px 20px; }
    }

    img {
      width: 50px;
      @include border-radius(100%);
    }

    .media-body {
      margin-left: 15px;
      &.reverse {
        margin-left: 0;
        margin-right: 15px;
      }
    }

    .msg {
      max-width: 80%;
      font-size: 13px;

      + .msg { margin-top: 5px; }
      p {
        padding: 12px 15px;
        background-color: $slim-primary;
        color: #fff;
        display: inline-block;
        margin-bottom: 0;
      }
    }

    .reverse {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .msg p {
        background-color: $gray-300;
        color: $body-color;
      }
    }
  }
}

.message-footer {
  position: absolute;
  height: 65px;
  border-top: 1px solid $gray-400;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 0 10px;

  .row {
    height: 100%;
    align-items: center;
    margin: 0;
  }

  .form-control {
    border: 0;
    padding-left: 0;
    padding-right: 0;
  }

  a {
    display: inline-block;
    color: $gray-600;

    + a { margin-left: 15px; }

    &:hover,
    &:focus { color: theme-color('primary'); }
  }

  .icon {
    font-size: 24px;
    &.ion-happy-outline { font-size: 21px; }
  }
}

/********** RTL SUPPORT **********/
/*********************************/
[dir="rtl"] {
  .messages-list {
    text-align: right;

    .media-body {
      margin-left: 0;
      margin-right: 15px;
    }
  }

  .message-header {
    .media {
      .media-body {
        margin-left: 0;
        margin-right: 15px;
        text-align: right;
      }
    }

    .message-option {
      margin-left: 0;
      margin-right: auto;
    }
  }

  .message-body {
    text-align: right;
    .media-list {
      .media-body {
        margin-left: 0;
        margin-right: 15px;

        &.reverse {
          margin-left: 15px;
          margin-right: 0;
        }
      }
    }
  }
}
