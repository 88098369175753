/* ------------------------------------------ */
/* ############### Pagination ############### */
/* ------------------------------------------ */

.pagination {
  .page-item {
    + .page-item {
      padding-left: 5px;
      margin: 0;
    }

    .page-link {
      color: $gray-700;
      padding: 11px 0;
      width: 38px;
      height: 40px;
      text-align: center;
      border-radius: 0;
      border-color: $gray-400;

      &:hover,
      &:focus {
        background-color: $gray-200;
      }
    }
  }

  .active {
    .page-link {
      &,&:hover,&:focus {
        color: #fff;
        background-color: theme-color('primary');
        border-color: transparent;
      }
    }
  }
}

.pagination-dark {
  .page-item {
    &.disabled {
      .page-link {
        background-color: transparent;
        color: rgba(#fff,0.2);
      }
    }

    &.active {
      .page-link {
        border-color: transparent;
        background-color: #fff;
        color: $gray-800;
      }
    }

    .page-link {
      color: rgba(#fff, 0.75);
      border-color: rgba(#fff,.3);
      text-align: center;
      padding-left: 0;
      padding-right: 0;

      &:hover,
      &:focus {
        background-color: rgba(#000,.05);
        color: #fff;
      }
    }

    span.page-link { border: 0; }
  }
}

// Pagination color variant
.pagination-primary { @include pagination-variant(theme-color('primary')); }
.pagination-success { @include pagination-variant(theme-color('success')); }
.pagination-warning { @include pagination-variant(theme-color('warning')); }
.pagination-danger { @include pagination-variant(theme-color('danger')); }
.pagination-info { @include pagination-variant(theme-color('info')); }
.pagination-inverse { @include pagination-variant($gray-800); }
.pagination-indigo { @include pagination-variant($indigo); }
.pagination-purple { @include pagination-variant($purple); }
.pagination-pink { @include pagination-variant($pink); }
.pagination-orange { @include pagination-variant($orange); }
.pagination-teal { @include pagination-variant($teal); }


.pagination-circle {
  .page-item {
    + .page-item { margin-left: 5px; }

    .page-link {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      padding: 0;
      border: 1px solid $gray-300;
      @include border-radius(100%);
    }

    span.page-link { border: 0; }

    &.active {
      .page-link {
        &,&:hover,&:focus {
          border-color: transparent;
        }
      }
    }
  }

  &.pagination-dark {
    .page-item {
      .page-link { border-color: rgba(#fff, 0.2); }
    }

    .active {
      .page-link { border-color: transparent; }
    }
  }
}

.pagination-circle {
  .page-item {
    span.page-link {
      border-color: transparent;
      padding-left: 0;
      padding-right: 0;
      width: auto;
    }

    &.disabled .page-link { background-color: transparent; }
  }
}

.pagination-dark {
  .page-link {
    background-color: transparent;
    color: #fff;
    border-color: rgba(#fff, .2);

    &:hover,
    &:focus {
      background-color: $gray-800;
    }
  }
}


/****** RTL SUPPORT ******/
/*************************/
[dir="rtl"] {
  .pagination {
    .page-item {
      + .page-item {
        padding-left: 0;
        padding-right: 5px;
      }
    }
  }
}
