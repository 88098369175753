/*
* Slim v1.0.0 (https://themepixels.me/slim)
* Copyright 2018 ThemePixels
* Licensed under ThemeForest License
*
* ---------- TABLE OF CONTENTS ----------
*
* 1. GOOGLE FONTS IMPORT
* 2. BOOTSTRAP FRAMEWORK
* 3. BOOTSTRAP OVERRIDES
*    3.1  Accordion
*    3.2  Alerts
*    3.3  Buttons
*    3.4  Card
*    3.5  Carousel
*    3.6  Close
*    3.7  Custom Forms
*    3.8  Custom
*    3.9  Dropdown
*    3.10 Forms
*    3.11 Form Layouts
*    3.12 Input groups
*    3.13 List group
*    3.14 Modal
*    3.15 Nav
*    3.16 Pagination
*    3.17 Popover
*    3.18 Progress
*    3.19 Tooltip
*    3.20 Table
*
* 4. CUSTOM VENDOR STYLES
*    4.1  Chartist
*    4.2  Datatables
*    4.3  Flot
*    4.4  Full Calendar
*    4.5  Ion RangeSlider
*    4.6  jQuery Steps
*    4.7  JQVMap
*    4.8  jQuery UI
*    4.9  Medium Editor
*    4.10 Morris
*    4.11 Parsley
*    4.12 Perfect Scrollbar
*    4.13 Rickshaw
*    4.14 Select2
*    4.15 Sparkline
*    4.16 Spectrum
*    4.17 Summernote
*    4.18 Tagsinput
*    4.19 Toggles
*
* 5. TEMPLATE LAYOUT STYLES
*    5.1  Demo
*    5.2  Header
*    5.3  Global
*    5.4  Navbar
*    5.5  Settings
*    5.6  Mainpanel
*    5.7  Sticky Header
*    5.8  Footer
*    5.9  Full Width
*
* 6. TEMPLATE PAGES STYLES
*    6.1  Contacts
*    6.2  Dashboard
*    6.3  Dropdown
*    6.4  Error
*    6.5  File Manager
*    6.6  Form Layouts
*    6.7  Invoice
*    6.8  Media
*    6.9  Messages
*    6.10 Modal
*    6.11 Pagination
*    6.12 Popover
*    6.13 Pricing
*    6.14 Profile
*    6.15 Signin
*    6.16 Timeline
*    6.17 Tooltip
*    6.18 Widgets
*    6.19 Landing
*
* 7. UTILITIES / HELPER CLASSES
*    7.1  Background
*    7.2  Border
*    7.3  Height
*    7.4  Hover
*    7.5  Margin
*    7.6  Misc
*    7.7  Padding
*    7.8  Position
*    7.9  Typography
*    7.10 Width
*/

/* ------------------------------------------------------ */
/* ############### 1. GOOGLE FONTS IMPORT ############### */
/* ------------------------------------------------------ */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');

// Bootstrap variable overrides
@import "bootstrap/variables";
@import "variables";

/* ------------------------------------------------------ */
/* ############### 2. BOOTSTRAP FRAMEWORK ############### */
/* ------------------------------------------------------ */
//@import "../bower_components/bootstrap/scss/bootstrap";
@import "bootstrap/scss/bootstrap.scss"; //cs

// Custom mixins for bootstrap
@import "bootstrap/mixins";

/* ------------------------------------------------------ */
/* ############### 3. BOOTSTRAP OVERRIDES ############### */
/* ------------------------------------------------------ */
@import "bootstrap/accordion";
@import "bootstrap/alerts";
@import "bootstrap/buttons";
@import "bootstrap/card";
@import "bootstrap/carousel";
@import "bootstrap/close";
@import "bootstrap/custom-forms";
@import "bootstrap/custom";
@import "bootstrap/dropdown";
@import "bootstrap/forms";
@import "bootstrap/form-layouts";
@import "bootstrap/input-group";
@import "bootstrap/list-group";
@import "bootstrap/modal";
@import "bootstrap/nav";
@import "bootstrap/pagination";
@import "bootstrap/popover";
@import "bootstrap/progress";
@import "bootstrap/tooltip";
@import "bootstrap/tables";

// Custom mixins for lib styles
@import "lib/mixins";

/* ------------------------------------------------------- */
/* ############### 4. CUSTOM VENDOR STYLES ############### */
/* ------------------------------------------------------- */
@import "lib/chartist";
@import "lib/datatables";
@import "lib/flot";
@import "lib/fullcalendar";
@import "lib/ionrangeslider";
@import "lib/jquery-steps";
@import "lib/jqvmap";
@import "lib/jquery-ui";
@import "lib/medium-editor";
@import "lib/morris";
@import "lib/parsley";
@import "lib/perfect-scrollbar";
@import "lib/rickshaw";
@import "lib/select2";
@import "lib/sparkline";
@import "lib/spectrum";
@import "lib/summernote";
@import "lib/tagsinput";
@import "lib/toggles";


/* --------------------------------------------------------- */
/* ############### 5. TEMPLATE LAYOUT STYLES ############### */
/* --------------------------------------------------------- */
@import "layout/demo";
@import "layout/header";
@import "layout/global";
@import "layout/navbar";
@import "layout/settings";
@import "layout/mainpanel";
@import "layout/sticky-header";
@import "layout/footer";
@import "layout/full-width";

/* -------------------------------------------------------- */
/* ############### 6. TEMPLATE PAGES STYLES ############### */
/* -------------------------------------------------------- */
@import "pages/contacts";
@import "pages/dashboard";
@import "pages/dropdown";
@import "pages/error";
@import "pages/file-manager";
@import "pages/form-layouts";
@import "pages/invoice";
@import "pages/media";
@import "pages/messages";
@import "pages/modal";
@import "pages/pagination";
@import "pages/popover";
@import "pages/pricing";
@import "pages/profile";
@import "pages/signin";
@import "pages/timeline";
@import "pages/tooltip";
@import "pages/widgets";
@import "pages/landing";

/* ------------------------------------------------------------- */
/* ############### 7. UTILITIES / HELPER CLASSES ############### */
/* ------------------------------------------------------------- */
@import "util/background";
@import "util/border";
@import "util/height";
@import "util/hover";
@import "util/margin";
@import "util/misc";
@import "util/padding";
@import "util/position";
@import "util/typography";
@import "util/width";
