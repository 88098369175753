/* ------------------------------------------ */
/* ############### Background ############### */
/* ------------------------------------------ */

.bg-transparent { background-color: transparent; }

.bg-gray-100 { background-color: $gray-100; }
.bg-gray-200 { background-color: $gray-200; }
.bg-gray-300 { background-color: $gray-300; }
.bg-gray-400 { background-color: $gray-400; }
.bg-gray-500 { background-color: $gray-500; }
.bg-gray-600 { background-color: $gray-600; }
.bg-gray-700 { background-color: $gray-700; }
.bg-gray-800 { background-color: $gray-800; }
.bg-gray-900 { background-color: $gray-900; }

.bg-white-1 { background-color: rgba(#fff, .1); }
.bg-white-2 { background-color: rgba(#fff, .2); }
.bg-white-3 { background-color: rgba(#fff, .3); }
.bg-white-4 { background-color: rgba(#fff, .4); }
.bg-white-5 { background-color: rgba(#fff, .5); }
.bg-white-6 { background-color: rgba(#fff, .6); }
.bg-white-7 { background-color: rgba(#fff, .7); }
.bg-white-8 { background-color: rgba(#fff, .8); }
.bg-white-9 { background-color: rgba(#fff, .9); }

.bg-black-1 { background-color: rgba(#000, .1); }
.bg-black-2 { background-color: rgba(#000, .2); }
.bg-black-3 { background-color: rgba(#000, .3); }
.bg-black-4 { background-color: rgba(#000, .4); }
.bg-black-5 { background-color: rgba(#000, .5); }
.bg-black-6 { background-color: rgba(#000, .6); }
.bg-black-7 { background-color: rgba(#000, .7); }
.bg-black-8 { background-color: rgba(#000, .8); }
.bg-black-9 { background-color: rgba(#000, .9); }

.bg-indigo { background-color: $indigo; }
.bg-purple { background-color: $purple; }
.bg-pink { background-color: $pink; }
.bg-orange { background-color: $orange; }
.bg-teal { background-color: $teal; }

// background gradient
.bg-mantle { @include gradient-x(#514A9D, #24C6DC); }
.bg-reef { @include gradient-x(#3a7bd5, #00d2ff); }
.bg-transfile { @include gradient-x(#CB3066, #16BFFD); }
.bg-neon { @include gradient-x(#12FFF7, #B3FFAB); }
.bg-delicate { @include gradient-x(#E9E4F0, #D3CCE3); }
.bg-emerald { @include gradient-x(#56B4D3, #348F50); }
.bg-mojito { @include gradient-x(#1D976C, #93F9B9); }
.bg-dance { @include gradient-x(#F9D423, #FF4E50); }
.bg-teal-love { @include gradient-x(#11FFBD, #AAFFA9); }
.bg-crystal-clear { @include gradient-x(#155799, #159957); }
.bg-grandeur { @include gradient-x(#000046, #1CB5E0); }
.bg-flickr { @include gradient-x(#33001b, #e20477); }
.bg-royal { @include gradient-x(#141E30, #243B55); }
.bg-firewatch { @include gradient-x(#cb2d3e, #ef473a); }

// extra gradient
.bg-teal-info { @include gradient-x($teal, theme-color('info')); }
