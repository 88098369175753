/* ------------------------------------------ */
/* ############### Summernote ############### */
/* ------------------------------------------ */

.note-editor {
  &.note-frame {
    border: 1px solid $gray-400;

    .note-editing-area .note-editable { padding: 10px; }
    .note-statusbar { border-top: 1px solid $gray-400; }
  }
}

.note-toolbar { position: relative; }

.card-header.note-toolbar {
  padding: 10px;
  background-color: $gray-100;
  @include border-top-radius(3px);

  > .btn-group {
    margin: 0 10px 10px 0;

    &.note-insert,
    &.note-view { margin-bottom: 0; }

    @media (min-width: 480px) {
      &.note-para,
      &.note-table { margin-bottom: 0; }
    }

    @include media-breakpoint-up(md) {
      &.note-para,
      &.note-table,
      &.note-insert { margin-bottom: 10px; }
    }

    @include media-breakpoint-up(lg) {
      &.note-insert { margin-bottom: 0; }
    }

    @include media-breakpoint-up(xl) {
      &,&.note-para,&.note-table { margin-bottom: 0; }
    }


    .btn {
      background-color: #fff;
      border: 1px solid $gray-400;
      padding: 8px 10px;
      color: $gray-700;
      font-size: 12px;
      @include border-radius(2px);

      &:hover,
      &:focus,
      &:active,
      &.active {
        border-color: $gray-500;
        background-color: $gray-200;
      }
    }

    .dropdown-item {
      padding: 7px 15px;

      &:hover,
      &:focus {
        background-color: $gray-200;
      }

      p,
      blockquote,
      pre {
        margin-bottom: 0;
        font-family: $font-family-base;
        font-size: 13px;
      }

      h1,h2,h3,h4,h5,h6 {
        font-size: 13px;
        font-weight: 400;
      }
    }

    .dropdown-toggle::after { margin-left: 8px; }

    &.note-color .dropdown-toggle::after { margin-left: -3px; }

    .dropdown-fontname {
      .dropdown-item {
        padding-left: 5px;

        span {
          font-family: $font-family-base !important;
          font-size: 12px;
        }
      }
    }
  }
}
