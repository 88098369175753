/* -------------------------------------------- */
/* ############### FILE MANAGER ############### */
/* -------------------------------------------- */

.file-item {
  background-color: #fff;
  border: 1px solid $gray-400;
  padding: 15px;
  display: flex;
  align-items:  center;
  justify-content: flex-start;

  @include media-breakpoint-up(sm) {
    height: 60px;
    padding: 0 25px;
  }

  .row { align-items: center; }

  .fa {
    margin-right: 10px;

    &.fa-file-image-o { color: theme-color('primary'); }
    &.fa-file-pdf-o { color: theme-color('danger'); }
    &.fa-file-video-o { color: theme-color('success'); }
    &.fa-file-sound-o { color: theme-color('warning'); }
  }

  a {
    color: $gray-700;
    &:hover,
    &:focus { color: $gray-800; }
  }

  + .file-item {
    margin-top: -1px;
  }
}


/********** RTL SUPPORT **********/
/*********************************/
[dir="rtl"] {
  .file-item {
    .fa {
      margin-right: 0;
      margin-left: 10px;
    }
  }
}
