/* --------------------------------------- */
/* ############### INVOICE ############### */
/* --------------------------------------- */

.card-invoice {
  .card-body {
    padding: 30px;
    @include media-breakpoint-up(md) { padding: 60px; }
  }
}

.invoice-header {
  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
}

.invoice-title {
  margin-bottom: 0;
  text-transform: uppercase;
  color: $gray-400;
  font-family: $font-family-montserrat;
  font-weight: 700;
}

.billed-from {
  margin-top: 25px;
  @include media-breakpoint-up(md) { margin-top: 0; }

  h6 {
    color: theme-color('primary');
    font-size: 15px;
  }

  p { line-height: 1.7; }
}

.billed-to {
  h6 {
    color: $gray-800;
    font-size: 15px;
  }

  p { line-height: 1.7; }
}

.invoice-info-row {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  margin-bottom: 0;

  + .invoice-info-row { border-top: 1px dashed $gray-300; }

  span {
    &:first-child { color: $gray-700; }
  }
}

.table-invoice {
  tbody > tr {
    > th,
    > td {
      &:first-child {
        color: $gray-700;
        font-weight: 500;

        .invoice-notes {
          margin-right: 20px;
          .section-label-sm { font-weight: 600; }
          p {
            font-size: 13px;
            font-weight: 400;
            color: $body-color;
          }
        }
      }
    }
  }
}


/********** RTL SUPPORT **********/
/*********************************/
[dir="rtl"] {
  .card-invoice {
    text-align: right;
  }

  .table-invoice {
    thead,
    tbody {
      tr {
        th,
        td {
          &.tx-left { text-align: left; }
        }
      }
    }

    tbody > tr {
      > th,
      > td {
        &:first-child {
          .invoice-notes {
            margin-right: 0;
            margin-left: 20px;
          }
        }
      }
    }
  }
}
