/* ------------------------------------- */
/* ############### MODAL ############### */
/* ------------------------------------- */

.modal-wrapper-demo {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: $gray-700;
}

.modal-footer-demo {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  background-color: $gray-800;

  .btn {
    font-size: 12px;
    text-transform: uppercase;
    padding: 12px 25px;
    font-family: $font-family-montserrat;
    font-weight: 600;
  }
}
