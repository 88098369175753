/* -------------------------------------------- */
/* ############### Custom Forms ############### */
/* -------------------------------------------- */

/* Checkbox */
.ckbox {
  font-weight: normal;
  position: relative;
  display: block;
  line-height: 18px;

  span {
    padding-left: 6px;

    &:empty { float: left; }

    &:before,
    &:after {
      line-height: 18px;
      position: absolute;
    }

    &:before {
      content: '';
      width: $ckbox-size;
      height: $ckbox-size;
      background-color: #fff;
      border: 1px solid $gray-500;
      top: 1px;
      left: 0;
    }

    &:after {
      top: 1px;
      left: 0;
      width: $ckbox-size;
      height: $ckbox-size;
      content: '\f00c';
      font-family: 'FontAwesome';
      font-size: 9px;
      text-align: center;
      color: #fff;
      background-color: theme-color('primary');
      line-height: 17px;
      display: none;
    }
  }

  input[type='checkbox'] {
    opacity: 0;
    margin: 0 5px 0 0;

    &:checked {
      + span:after { display: block; }
    }

    &[disabled] {
      + span,
      + span:before,
      + span:after {
        opacity: .75;
      }
    }
  }
}

.ckbox-inline { display: inline-block; }

/* Checkbox color variant */
.ckbox-success { span::after { background-color: theme-color('success'); } }
.ckbox-warning { span::after { background-color: theme-color('warning'); } }
.ckbox-danger { span::after { background-color: theme-color('danger'); } }
.ckbox-info { span::after { background-color: theme-color('info'); } }
.ckbox-indigo { span::after { background-color: $indigo; } }
.ckbox-purple { span::after { background-color: $purple; } }
.ckbox-pink { span::after { background-color: $pink; } }
.ckbox-orange { span::after { background-color: $orange; } }
.ckbox-teal { span::after { background-color: $teal; } }
.ckbox-dark { span::after { background-color: $gray-800; } }


/* Radio Box */
.rdiobox {
  font-weight: normal;
  position: relative;
  display: block;
  line-height: 18px;

  span {
    padding-left: 6px;

    &:before,
    &:after {
      line-height: 18px;
      position: absolute;
    }

    &:before {
      content: '';
      width: $rdiobox-size;
      height: $rdiobox-size;
      background-color: #fff;
      border: 1px solid $gray-500;
      border-radius: 50px;
      top: 2px;
      left: 0;
    }

    &:after {
      content: '';
      width: $rdiobox-size - 10px;
      height: $rdiobox-size - 10px;
      background-color: #fff;
      border-radius: 50px;
      top: 7px;
      left: 5px;
      display: none;
    }
  }

  input[type='radio'] {
    opacity: 0;
    margin: 0 5px 0 0;

    &:checked {
      + span:before {
        border-color: transparent;
        background-color: theme-color('primary');
      }
      + span:after { display: block; }
    }

    &[disabled] {
      + span,
      + span:before,
      + span:after {
        opacity: .75;
      }
    }
  }
}

.rdiobox-inline { display: inline-block; }

/* Radiobox Color Variant */
.rdiobox-success { @include rdiobox-color-variant(theme-color('success')); }
.rdiobox-warning { @include rdiobox-color-variant(theme-color('warning')); }
.rdiobox-danger { @include rdiobox-color-variant(theme-color('danger')); }
.rdiobox-info { @include rdiobox-color-variant(theme-color('info')); }
.rdiobox-indigo { @include rdiobox-color-variant($indigo); }
.rdiobox-purple { @include rdiobox-color-variant($purple); }
.rdiobox-orange { @include rdiobox-color-variant($orange); }
.rdiobox-pink { @include rdiobox-color-variant($pink); }
.rdiobox-teal { @include rdiobox-color-variant($teal); }
.rdiobox-dark { @include rdiobox-color-variant($gray-800); }


/* Inside input group */
.input-group-addon {
  .ckbox,
  .rdiobox {
    &,input { margin: 0; }
  }
}


/* File Browser */
.custom-file { direction: ltr; }
.custom-file-label {
  &,&::after { border-radius: 0; }
}
.custom-file-label-primary::after {
  background-color: theme-color('primary');
  border-color: transparent;
  color: #fff;
}

.custom-file-label-inverse::after {
  background-color: $gray-800;
  border-color: transparent;
  color: #fff;
}

.custom-file-label-primary,
.custom-file-label-inverse {
  &::after {
    top: -1px;
    bottom: -1px;
    right: -1px;
    height: auto;
  }
}

/* ########## RTL SUPPORT ########## */

[dir="rtl"] {
  .ckbox {
    text-align: right;
    span {
      padding-left: 0;
      padding-right: 6px;

      &:empty { float: right; }

      &:before,
      &:after {
        left: auto;
        right: 0;
      }
    }

    input[type='checkbox'] { margin: 0 0 0 5px; }
  }

  .rdiobox {
    text-align: right;
    span {
      padding-left: 0;
      padding-right: 6px;

      &:before {
        left: auto;
        right: 0;
      }

      &:after {
        left: auto;
        right: 5px;
      }
    }

    input[type='radio'] { margin: 0 0 0 5px; }
  }
}
