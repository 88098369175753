/* --------------------------------------------- */
/* ############### Full Calendar ############### */
/* --------------------------------------------- */

.fc-toolbar {
  &.fc-header-toolbar {
    margin-bottom: 0;
    @media (min-width: 400px) { margin-bottom: 10px; }
    @include media-breakpoint-up(sm) { margin-bottom: 15px; }
  }

  h2 {
    font-size: 20px;
    color: $gray-800;
    margin-bottom: 0;
    height: 40px;
    display: flex;
    align-items: center;
  }

  .fc-button {
    border: 0;
    background-color: $gray-500;
    background-image: none;
    box-shadow: none;
    color: #fff;
    border-radius: 0;
    text-shadow: none;
    height: 40px;
    padding-left: 12px;
    padding-right: 12px;
    line-height: 0;

    &:hover,
    &:focus {
      background-color: $gray-600;
    }

    &:active {
      background-color: $gray-700;
    }
  }

  .fc-today-button {
    text-transform: uppercase;
    font-size: 12px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.fc-unthemed {
  .fc-head {
    background-color: theme-color('primary');

    th,td {
      border-color: lighten(theme-color('primary'), 5%);
      text-transform: uppercase;

      &:first-child { border-left: 0; }

      span {
        display: block;
        padding: 10px 0;
        font-size: 12px;
        color: #fff;
      }
    }
  }

  tbody {
    tr td {
      border-color: $gray-300;
      background-color: #fff;

      .fc-day-number {
        padding: 10px;
        font-family: $font-family-lato;
      }

      .fc-today { background-color: $gray-100; }
    }
  }
}

.fc-day-top.fc-other-month { opacity: .65; }
